import { AddressDto } from './address';

export interface FormPlanTrip {
  PointA: AddressDto | null;
  PointB: AddressDto | null;
  Date: string;
  Time: string;
  HourType: HourTypeChoice;
  AssistiveDevices: Array<string>;
  isError: boolean;
}

export enum HourTypeChoice {
  Arrival = 'Arrival',
  Departure = 'Departure',
}
