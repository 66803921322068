
const IconSwitch = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.5392" cy="18.5393" r="18.2876" transform="rotate(-0.793892 18.5392 18.5393)" fill="currentColor"/>
      <path
        // eslint-disable-next-line max-len
        d="M12.9901 26.7261C13.2829 27.019 13.7578 27.019 14.0507 26.7261L18.8237 21.9531C19.1166 21.6602 19.1166 21.1853 18.8237 20.8924C18.5308 20.5995 18.0559 20.5995 17.763 20.8924L13.5204 25.1351L9.27775 20.8924C8.98485 20.5995 8.50998 20.5995 8.21708 20.8924C7.92419 21.1853 7.92419 21.6602 8.21708 21.9531L12.9901 26.7261ZM12.7704 10.9854L12.7704 26.1957L14.2704 26.1957L14.2704 10.9854L12.7704 10.9854Z"
        fill="#222121"
      />
      <path
        // eslint-disable-next-line max-len
        d="M24.191 10.455C23.8981 10.1621 23.4232 10.1621 23.1303 10.455L18.3573 15.228C18.0645 15.5209 18.0645 15.9958 18.3573 16.2887C18.6502 16.5815 19.1251 16.5815 19.418 16.2887L23.6606 12.046L27.9033 16.2887C28.1962 16.5815 28.6711 16.5815 28.9639 16.2887C29.2568 15.9958 29.2568 15.5209 28.9639 15.228L24.191 10.455ZM22.9106 10.9854L22.9106 26.1957L24.4106 26.1957L24.4106 10.9854L22.9106 10.9854Z"
        fill="#222121"
      />
    </svg>
  );
};

export default IconSwitch;