import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import journeyApi from '../../../api/journey';
import Loader from '../../../layout/loader/Loader';
import { GroupedJourney, GroupedJourneyItem } from '../../../models/grouped-journey';
import { selectCustomer } from '../../../store/customerSlice';
import InformationAlert from '../../alert/information-alert/InformationAlert';
import ServerError from '../../alert/server-error/ServerError';
import './RegularTrips.scss';
import RegularTripCard from './regular-trip-card/RegularTripCard';
import RegularTripDialog from './regular-trip-dialog/RegularTripDialog';
import ReactGA from 'react-ga';

const RegularTrips = () => {
  const { t, i18n } = useTranslation();
  const [regularTrips, setRegularTrips] = useState<GroupedJourneyItem[]>([]);
  const [selectedTrip, setSelectedTrip] = useState<GroupedJourneyItem | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasServerError, setHasServerError] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const customer = useSelector(selectCustomer);

  const fetchTrips = (customerId: number, lang: string) => {
    setIsLoading(true);
    setHasServerError(false);
    journeyApi.getCustomerJourneys(customerId.toString(), 'Template', 'Active', `${lang}-CA`)
      .then((response) => {
        const groupedJourney = new GroupedJourney(response.data.journeys);
        setRegularTrips(groupedJourney.groupedJourneys);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setRegularTrips([]);
        setIsLoading(false);
        setHasServerError(true);
      });
  };

  useEffect(() => {
    // Track a custom event
    ReactGA.event({
      category: 'Trip',
      action: 'My trips',
      label: 'Regular trips',
    });
    if (customer) {
      fetchTrips(customer.identifier, i18n.language);
    } else {
      setIsLoading(false);
    }
  }, [customer, i18n.language]);

  const handleTripDetails = (trip: GroupedJourneyItem) => {
    setSelectedTrip(trip);
    setIsDialogOpened(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpened(false);
  };

  return (
    <div className="RegularTrips">
      { isLoading && <Loader></Loader> }
      { hasServerError && <ServerError>{t('error.tripsServerErrorText')}</ServerError> }
      { !hasServerError && <p className="RegularTripsIntro">{t('trips.regularTripsReservations')}</p> }
      { ((!regularTrips || regularTrips?.length === 0) && !isLoading && !hasServerError) && <p className="noTrips">{t('trips.noRegularTrip')}</p> }
      { regularTrips?.length > 0 &&
      <>
        <section className="TripsList">
          {regularTrips.map((trip, index) => (
            <RegularTripCard
              key={index}
              trip={trip}
              onTripDetails={handleTripDetails}
            />
          ))}
        </section>
        <InformationAlert title={t('cancelTrip.regularCancellationTitle')}>
          <span dangerouslySetInnerHTML={{ __html: t('cancelTrip.regularCancellationText') }}></span>
        </InformationAlert>
      </>
      }
      <RegularTripDialog
         trip={selectedTrip}
         isOpened={isDialogOpened}
         onCloseDialog={handleCloseDialog}
         customer={customer}
      />
    </div>
  );
};

export default RegularTrips;
