import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTabPanel from '../../components/trips/common/custom-tab-panel/CustomTabPanel';
import NextTrips from '../../components/trips/next-trips/NextTrips';
import RegularTrips from '../../components/trips/regular-trips/RegularTrips';
import PageWrapper from '../../layout/page-wrapper/PageWrapper';
import './Trips.scss';

const boxStyling = {
  borderBottom: 1,
  borderColor: 'divider',
  display: 'flex',
  justifyContent: 'center',
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}


const Trips = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [nextTripsLabel, setNextTripsLabel] = useState('');
  const [regularTripsLabel, setRegularTripsLabel] = useState('');

  useEffect(()=>{
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setNextTripsLabel(t('trips.nextTripsLabelMobile'));
        setRegularTripsLabel(t('trips.regularTripsLabelMobile'));
      } else {
        setNextTripsLabel(t('trips.nextTripsLabel'));
        setRegularTripsLabel(t('trips.regularTripsLabel'));
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [t]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PageWrapper title={t('trips.myTrips')}>
      <div className="TripsTabs">
        <Box sx={boxStyling}>
          <Tabs value={value} onChange={handleChange} aria-label={t('trips.tripsTypeSelection')}>
            <Tab label={nextTripsLabel} {...a11yProps(0)} tabIndex={value === 0 ? -1 : 0}/>
            <Tab label={regularTripsLabel} {...a11yProps(1)} tabIndex={value === 1 ? -1 : 0}/>
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} aria-label={t('trips.next14DaysTrips')}>
          <NextTrips />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} aria-label={t('trips.regularTripsReservations')}>
          <RegularTrips />
        </CustomTabPanel>
      </div>
    </PageWrapper>
  );
};

export default Trips;