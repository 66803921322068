export const CustomMuiTabs = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: '#2B373B',
            height: '4px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#2D3648',
          fontWeight: 500,
          textTransform: 'none',
          fontSize: '18px',
          padding: '18px 40px',

          [theme.breakpoints.down('md')]: {
            padding: '18px 30px',
            fontSize: '16px',
          },

          '&.Mui-selected': {
            color: '#2D3648',
            fontWeight: 700,
          },
        },
      },
    },
  };
};
