import { createTheme } from '@mui/material/styles';
import { colorTheme } from './Colors/ColorTheme';
import { TypographyTheme } from './Typography/TypographyTheme';
import { shadowTheme } from './Shadow/ShadowTheme';
import { CustomMuiButton } from './Button/ButtonTheme';
import { CustomMuiTextField } from './TextField/TextFieldTheme';
import { CustomMuiRadio } from './Selection/RadioButton/RadioButtonTheme';
import { CustomMuiSlider } from './Selection/Slider/SliderTheme';
import { CustomMuiSwitch } from './Selection/Switch/SwitchTheme';
import { CustomMuiCheckbox } from './Selection/CheckboxTheme';
import { CustomMuiDatePicker } from './Selection/DatePicker/DatePickerTheme';
import { CustomMuiDateRangePicker } from './Selection/DatePicker/DateRangePickerTheme';
import { CustomMuiChip } from './Chip/ChipTheme';
import { CustomMuiSnackBar } from './SnackBar/SnackBarTheme';
import { CustomMuiDialog } from './Dialog/DialogTheme';
import { CustomMuiDialogTitle } from './Dialog/DialogTitleTheme';
import { CustomMuiMobileStepper } from './Stepper/MobileStepperTheme';
import { CustomMuiToolTipTheme } from './ToolTip/ToolTipTheme';
import { CustomMuiTabs } from './Tabs/TabsTheme';

export const theme = createTheme({
  palette: colorTheme,
  typography: TypographyTheme,
  shadows: shadowTheme,
});

CustomMuiButton(theme);
CustomMuiTextField(theme);
CustomMuiRadio(theme);
CustomMuiSlider(theme);
CustomMuiSwitch(theme);
CustomMuiCheckbox(theme);
CustomMuiDatePicker(theme);
CustomMuiDateRangePicker(theme);
CustomMuiChip(theme);
CustomMuiSnackBar(theme);
CustomMuiDialog(theme);
CustomMuiDialogTitle(theme);
CustomMuiMobileStepper(theme);
CustomMuiToolTipTheme(theme);
CustomMuiTabs(theme);
