import InfoIcon from '@mui/icons-material/Info';
import { Alert, AlertTitle } from '@mui/material';
import './InformationAlert.scss';

const InformationAlert = (props: any) => {
  return (
    <Alert className="InformationAlert" severity="info" icon={<InfoIcon />}>
      <AlertTitle>{props.title}</AlertTitle>
      {props.children}
    </Alert>
  );
};

export default InformationAlert;