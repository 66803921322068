import axios, { AxiosPromise } from 'axios';
import { CustomerDto } from '../models/customer';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const customerApi = {
  getMe(Culture?: string): AxiosPromise<{ customer: CustomerDto }> {
    return axios({
      method: 'GET',
      baseURL: API_BASE_URL,
      url: '/customers/me',
      params: {
        Culture,
      },
    });
  },
  getCustomer(
    customerId: string,
    Culture?: string,
  ): AxiosPromise<{ customer: CustomerDto }> {
    return axios({
      method: 'GET',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}`,
      params: {
        Culture,
      },
    });
  },
};


export default customerApi;
