import { useSelector } from 'react-redux';
import BackToMainMenu from '../../components/back-to-main-menu/BackToMainMenu';
import CustomerError from '../../layout/customer-error/CustomerError';
import { selectHasCustomer } from '../../store/customerSlice';
import './PageWrapper.scss';

interface PageWrapperProps {
  children?: React.ReactNode;
  title: string;
}

const PageWrapper = ({ children, title }: PageWrapperProps) => {
  const hasCustomer = useSelector(selectHasCustomer);

  return (
    <div className="PageWrapper">
      <div className="PageWrapperBackToMainMenu">
        <BackToMainMenu />
      </div>
      <div className="container-fluid">
        <CustomerError />
        { hasCustomer && <>
          <div className="PageWrapperHeader">
            <h1>{title}</h1>
          </div>
          {children}
        </> }
      </div>
    </div>
  );
};

export default PageWrapper;