import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="Loader">
      <CircularProgress size={70} />
    </div>
  );
};

export default Loader;