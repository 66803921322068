export const CustomMuiDateRangePicker = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          fontSize: '34px',
          fontWeight: '400',
          lineHeight: '24px',
          color: theme.palette.error.main,
                            
        },
      },
    },
   
  };
};
