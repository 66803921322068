import './ErrorMessage.scss';
import ErrorIcon from '@mui/icons-material/Error';

interface ErrorMessageProprs {
  messageText: string;
}

const ErrorMessage = ({ messageText }: ErrorMessageProprs) => {
  return (
    <span className="error-container">
      <ErrorIcon className="errorIcon errorColor" />
      <span className="errorText errorColor">{messageText}</span>
    </span>
  );
};

export default ErrorMessage;
