const IconWatch = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M12 2.75C6.61547 2.75 2.25 7.11547 2.25 12.5C2.25 17.8845 6.61547 22.25 12 22.25C17.3845 22.25 21.75 17.8845 21.75 12.5C21.75 7.11547 17.3845 2.75 12 2.75ZM16.5 14H12C11.8011 14 11.6103 13.921 11.4697 13.7803C11.329 13.6397 11.25 13.4489 11.25 13.25V6.5C11.25 6.30109 11.329 6.11032 11.4697 5.96967C11.6103 5.82902 11.8011 5.75 12 5.75C12.1989 5.75 12.3897 5.82902 12.5303 5.96967C12.671 6.11032 12.75 6.30109 12.75 6.5V12.5H16.5C16.6989 12.5 16.8897 12.579 17.0303 12.7197C17.171 12.8603 17.25 13.0511 17.25 13.25C17.25 13.4489 17.171 13.6397 17.0303 13.7803C16.8897 13.921 16.6989 14 16.5 14Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default IconWatch;
