import { Box, Stack, Typography, styled } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApiCallStatus } from '../../../api/shared';
import {
  selectConfirmJourneyCallStatus,
  selectFormPlanTrip,
  selectJourney,
} from '../../../store/planTripSlice';
import MergedLabelIconWithLetter from '../../merged-label-icon-with-letter/MergedLabelIconWithLetter';
import PlanTripButtons from '../plan-trip-buttons/PlanTripButtons';
import PlanTripSuccess from '../plan-trip-success/PlanTripSuccess';
import './PlanTripStep3.scss';
import TagManager from 'react-gtm-module';
import PlanTripBanner from '../plan-trip-banner/PlanTripBanner';
import { Info } from '@mui/icons-material';
import { RequestedTimeType } from '../../../models/journey';
import { HourTypeChoice } from '../../../models/form-plan-trip';
 
TagManager.dataLayer({
  dataLayer: {
    event: 'planTrip',
    step: 3,
  }
});

const PlanTripStep3 = () => {
  dayjs.extend(isBetween);
  dayjs.extend(utc);
  const { t } = useTranslation();
  const planJourney = useSelector(selectJourney);
  const effectiveDate = dayjs(planJourney![0].effectiveDate);
  const callConfirmJourneyStatus = useSelector(selectConfirmJourneyCallStatus);
  const formPlanTrip = useSelector(selectFormPlanTrip);
  const formRequestedDateTime = dayjs(formPlanTrip.Date).set('hour', dayjs(formPlanTrip.Time).hour()).set('minute', dayjs(formPlanTrip.Time).minute());
  const earliestDropOffDateTimeUtc = dayjs(planJourney![0].earliestDropOffDateTime).utc();
  const latestDropOffDateTimeUtc = dayjs(planJourney![0].latestDropOffDateTime).utc();
  const earliestPickUpDateTimeUtc = dayjs(planJourney![0].earliestPickUpDateTime).utc();
  const latestPickUpDateTimeUtc = dayjs(planJourney![0].latestPickUpDateTime).utc();
  const arriveBetween = dayjs(formRequestedDateTime).isBetween(earliestDropOffDateTimeUtc, latestDropOffDateTimeUtc, 'minute', '[]');
  const departBetween = dayjs(formRequestedDateTime).isBetween(earliestPickUpDateTimeUtc, latestPickUpDateTimeUtc, 'minute', '[]');
  const showBannerTimeContrains = planJourney![0].requestedTimeType == RequestedTimeType.Arrival ? !arriveBetween : !departBetween;
  const attendantMandatory = planJourney![0].tripPassengers?.find(
    (el) => el === 'Attendant'
  );
  const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'start',
    color: theme.palette.text.primary,
  }));
  const handleSubmitForm = ():boolean => { return true;};

  return (
    <div className="PlanTripStep3" >
      {showBannerTimeContrains && (
          <PlanTripBanner 
            level='warning' 
            title={t('plan-trip.step3.timeChangetitle')} 
            text={formPlanTrip.HourType == HourTypeChoice.Arrival ?          
              t('plan-trip.step3.timeChangeArrivalText', { time: formRequestedDateTime.format('LT'), newtime: dayjs(planJourney![0].requestedTime).format('LT') }) :
              t('plan-trip.step3.timeChangeBoardingText', { time: formRequestedDateTime.format('LT'), newtime: dayjs(planJourney![0].requestedTime).format('LT') })
          }
            closeButton= {false} showIcon={<Info></Info>}></PlanTripBanner>
      )}
      {callConfirmJourneyStatus === ApiCallStatus.Success && (
        <PlanTripSuccess></PlanTripSuccess>
      )}
      {callConfirmJourneyStatus === ApiCallStatus.Success && (
        <h2 className="yourReservation">
          {t('plan-trip.step2.yourReservation')}
        </h2>
      )}
      {callConfirmJourneyStatus !== ApiCallStatus.Success && (
        <Typography variant="h1" component="h1" className="title-step">
          {t('plan-trip.step3.title')}
        </Typography>
      )}
      {callConfirmJourneyStatus !== ApiCallStatus.Success && (
        <Typography
          variant="subtitle1"
          component="h3"
          className="sub-title-step"
        >
          {t('plan-trip.step3.stepDescription')}
        </Typography>
      )}
      <div>
        <Stack spacing={2}>
          <Item tabIndex={0}>
            <Typography component="div" className="label">
              <MergedLabelIconWithLetter letter="A" />
              {t('plan-trip.step1.departureAddress')}
            </Typography>
            <Typography className="text">
              {planJourney![0].pickUpAddress.longFormattedDesc}
            </Typography>
          </Item>
          <Item tabIndex={0}>
            <Typography component="div" className="label">
              <MergedLabelIconWithLetter letter="B" />
              {t('plan-trip.step1.arrivalAddress')}
            </Typography>
            <Typography className="text">
              {planJourney![0].dropOffAddress.longFormattedDesc}
            </Typography>
          </Item>
          <Item tabIndex={0}>
            <Typography className="label">
              {t('plan-trip.step3.dateLabel')}
            </Typography>
            <Typography className="text">
              {t('plan-trip.step3.dateText', {
                dayName: effectiveDate.format('dddd'),
                dayNumber: effectiveDate.date(),
                month: effectiveDate.format('MMMM'),
                year: effectiveDate.year(),
              })}
            </Typography>
          </Item>

          <Item tabIndex={0}>
            <Typography className="label">
              {t('plan-trip.step3.boardingTimeLabel')}
            </Typography>
            <Typography className={'text ' + showBannerTimeContrains ? 'aa' : 'bb'}>
              {t('plan-trip.step3.boardingTimeText', {
                startTime: dayjs(planJourney![0].earliestPickUpDateTime).format(
                  'LT'
                ),
                endTime: dayjs(planJourney![0].latestPickUpDateTime).format(
                  'LT'
                ),
              })}
            </Typography>
          </Item>

          <Item tabIndex={0}>
            <Typography className="label">
              {t('plan-trip.step3.arrivalTimeLabel')}
            </Typography>
            <Typography className="text">
              {dayjs(planJourney![0].latestDropOffDateTime).format('LT')}
            </Typography>
          </Item>
          {!!attendantMandatory && (
            <Item tabIndex={0}>
              <Typography className="label">
                {t('plan-trip.step3.attendantLabel')}
              </Typography>
              <Typography className="text">
                {t('plan-trip.step1.attendantText')}
              </Typography>
            </Item>
          )}
          {planJourney![0].assistiveDevices &&
          planJourney![0].assistiveDevices.length > 0 && (
            <Item tabIndex={0}>
              <Typography className="label">
                {t('plan-trip.step3.assistiveDeviceLabel')}
              </Typography>
              {planJourney![0].assistiveDevices?.map((item, index) => (
                <Typography className="text" key={index}>
                  {item.description}
                </Typography>
              ))}
            </Item>
          )}
        </Stack>
      </div>
      <PlanTripButtons
        handleSubmit={handleSubmitForm}
      ></PlanTripButtons>
    </div>
  );
};

export default PlanTripStep3;
