import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fetchCustomerAsync } from '../../store/customerSlice';
import store from '../../store/store';
import './LanguageSwitcher.scss';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = () => {
    const newLanguage = i18n.language === 'fr' ? 'en' : 'fr';
    i18n.changeLanguage(newLanguage);
    dayjs.locale(newLanguage);
    store.dispatch(fetchCustomerAsync(i18n.language));
    navigate(`/${i18n.language}`);
  };

  return (
    <div className="LanguageSwitcher">
      <Button
        variant="text"
        arial-label={t('other-language-aria-label')}
        onClick={() => changeLanguage()}
      >{t('other-language')}</Button>
    </div>
  );
};

export default LanguageSwitcher;