import { Alert, IconButton, Collapse, AlertTitle } from '@mui/material';
import './PlanTripBanner.scss';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
type BannerProps = {
  title: string;
  text: string;
  level: string;
  closeButton: boolean;
  showIcon: ReactNode | boolean;
};
const PlanTripBanner = ({ text, title, level, closeButton, showIcon }: BannerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  return (
    <div className="planTripBanner">
      <Collapse in={open}>
        <Alert
          variant="filled"
          severity={level == 'warning' ? 'warning' : 'error'}
          className="banner"
          icon={showIcon}
          action={
            (closeButton && <IconButton
              aria-label={t('plan-trip.step2.bannerButtonCloseAria')}
              color="inherit"
              size="small"
              className="close-button"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>)
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle className="banner-title">{title}</AlertTitle>
          <div className="banner-text" dangerouslySetInnerHTML={{ __html: text }}></div>
        </Alert>
      </Collapse>
    </div>
  );
};

export default PlanTripBanner;
