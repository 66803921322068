import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './BackToMainMenu.scss';

const BackToMainMenu = () => {
  const { t, i18n } = useTranslation();

  return (
    <Link to={`/${i18n.language}`} className="backToMainMenu">
      <ArrowBackIcon />
      <span className="backToMainMenuMobileText">
        {t('header.homeLinkTextMobile')}
      </span>
      <span className="backToMainMenuDesktopText">
        {t('header.homeLinkText')}
      </span>
    </Link>
  );
};

export default BackToMainMenu;
