export interface AddressDto {
  addressLocation: Location;
  addressType: string;
  startDate: string;
  endDate: string;
  phoneInfo1: PhoneInfoDto;
  phoneInfo2: PhoneInfoDto;
  lastUsedDate: string;
}
export function newAddressDto(): AddressDto {
  return {
    addressLocation: {
      addressIdentifier: -1, // Set -1 here as we check this value to know if a selection is made by the user and there's address within Giro with 0 as it's ID.
      building: '',
      civicNumber: '',
      externalDescription: '',
      isServiceAddress: false,
      landmarkIdentifier: '',
      latitude: 0,
      longitude: 0,
      locationKey: {
        addressId: 0,
        distFromOrig: 0,
        distFromStreet: 0,
        streetSegmentNo: 0,
      },
      longFormattedDescription: '',
      municipality: {},
      municipalityName: '',
      postalCode: '',
      registeredAddressType: '',
      shortFormattedDescription: '',
      streetName: '',
      streetOrientation: '',
      streetType: {},
      suite: '',
    },
    addressType: '',
    startDate: '',
    endDate: '',
    phoneInfo1: {},
    phoneInfo2: {},
    lastUsedDate: ''
  };
}
export interface Location {
  addressIdentifier: number;
  building: '';
  civicNumber: string;
  externalDescription: string;
  isServiceAddress: boolean;
  landmarkIdentifier: string;
  latitude: number;
  longitude: number;
  locationKey: LocationKeyDto;
  longFormattedDescription: string;
  municipality: MunicipalityDto;
  municipalityName: string;
  postalCode: string;
  registeredAddressType: string;
  shortFormattedDescription: string;
  streetName: string;
  streetOrientation: string;
  streetType: StreetTypeDto;
  suite: string;
}

export interface LocationKeyDto {
  addressId: number;
  streetSide?: string;
  streetSegmentNo: number;
  distFromOrig: number;
  distFromStreet: number;
}

export interface StreetTypeDto {
  id?: string;
  description?: string;
}

export interface StreetOrientationDto {
  id: number;
  description?: string;
}

export interface MunicipalityDto {
  id?: string;
  name?: string;
  parentName1?: string;
  parentName2?: string;
  parentName3?: string;
  parentName4?: string;
}
export interface PhoneInfoDto {
  phoneExtension?: string;
  phoneNumber?: string;
  phoneType?: string;
}
