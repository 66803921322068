export const CustomMuiToolTipTheme = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: '#222121',
          border: '1px solid #dadde9',
          padding: '15px',
          fontWheight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
        },
        arrow: {
          '&:before': {
            border: '1px solid #E6E8ED',
          },
          color: '#FFFFFF',
        },
      },
    },
  };
};
