export const CustomMuiSlider = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiSlider: {
      styleOverrides: {
        root: {
          // default configuration for base slider
          color: theme.palette.primary.main,
          borderRadius: '2px',
          flex: 'none',
          order: 0,
          alignSelf: 'stretch',
          flexGrow: 0,
          margin: '8px 0px',
          '& .MuiSlider-thumb': {
            height: 20,
            width: 20,
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 2,
            },
            '&:before': {
              display: 'none',
            },
          },
        },
      },
    },
  };
};
