import React from 'react';
import { useTranslation } from 'react-i18next';
import { JourneyAddressDto, RequestedTimeType } from '../../../../models/journey';
import MergedLabelIconWithLetter from '../../../merged-label-icon-with-letter/MergedLabelIconWithLetter';
import './TripDetailsRoute.scss';
import  dayjs from 'dayjs';

interface TripDetailsRouteProps {
  pickUp: JourneyAddressDto,
  dropOff: JourneyAddressDto,
  earliestPickUpDateTime: string,
  latestPickUpDateTime: string,
  requestedTime: string,
  requestedTimeType?: RequestedTimeType
}

const TripDetailsRoute = ({
  pickUp,
  dropOff,
  earliestPickUpDateTime,
  latestPickUpDateTime,
  requestedTime,
  requestedTimeType,
}: TripDetailsRouteProps) => {
  const { t } = useTranslation();

  const formatTime = (date: string) => {
    return dayjs(date).format(t('trips.times.format'));
  };

  return (
    <div className="TripDetailsRoute">
      <h3 className="TripDetailsRouteTitle">{t('trips.route.title')}</h3>
      <div className="TripDetailsRouteItem">
        <div className="TripDetailsRouteItemIcon">
          <MergedLabelIconWithLetter letter="A" />
        </div>
        <div className="TripDetailsRouteItemDesc">
          <span>{pickUp?.longFormattedDesc}</span>
        </div>
      </div>
      <div className="TripDetailsRouteItem">
        <div className="TripDetailsRouteItemIcon">
          <MergedLabelIconWithLetter letter="B" />
        </div>
        <div className="TripDetailsRouteItemDesc">
          <span>{dropOff?.longFormattedDesc}</span>
        </div>
      </div>
      <div className="TripDetailsRouteTimes">
        <div>
          <h3 className="TripDetailsRouteTimesTitle">{t('trips.times.pickup')}</h3>
          <span
            className="TripDetailsRouteTimesValue"
            dangerouslySetInnerHTML={{ __html: t('trips.times.pickupTime', { from: formatTime(earliestPickUpDateTime), to: formatTime(latestPickUpDateTime) }) }}
          />
        </div>
        <div>
          <h3 className="TripDetailsRouteTimesTitle">{t(`trips.times.typeLong.${requestedTimeType}`)}</h3>
          <span className="TripDetailsRouteTimesValue">{formatTime(requestedTime)}</span>
        </div>
      </div>
    </div>
  );
};

export default TripDetailsRoute;