export const CustomMuiRadio = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiRadio: {
      styleOverrides: {
        root: {
          // default configuration for base radio
          color: theme.palette.grey[700],
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
          '& .MuiSvgIcon-root': {
            height: 20,
            width: 20,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled .MuiSvgIcon-root': {
            color: theme.palette.grey[400],
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          //default configuration for form control
          color: theme.palette.black.main,
        },
        label: {
          fontWeight: '400',
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
  };
};
