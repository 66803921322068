import React from 'react';
import './Maintenance.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WarningAlert from '../../components/alert/warning-alert/WarningAlert';

const Maintenance = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const errorMessage = location.state?.errorMessage;

  return (
    <div className="mainContainer">
      <div className="maintenanceContainer">
        <WarningAlert title={t('error.dailyMaintenanceErrorTitle')}>{errorMessage}</WarningAlert>
      </div>
    </div>
  );
};

export default Maintenance;