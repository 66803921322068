export const CustomMuiDatePicker = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: '318px',
          maxHeight: '270px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '24px',
          border: 'solid #8F9FA3 1px',
          borderRadius: '8px',
          boxShadow: 3,
          '& .MuiIconButton-edgeEnd, .MuiIconButton-edgeStart': {
            backgroundColor: theme.palette.black.main,
            color: theme.palette.white.main,
          },
          '& .MuiIconButton-edgeEnd:hover, .MuiIconButton-edgeStart:hover': {
            backgroundColor: theme.palette.black.main,
            color: theme.palette.white.main,
          },
          '& .MuiCalendarPicker-viewTransitionContainer': {
            overflowY: 'hidden',
          },
          '& .MuiPickersDay-root, .MuiTypography-caption': {
            color: theme.palette.black.main,  
                
            width: '32px',
            height: '32px',
          },
          '& .MuiPickersDay-root:hover': {
            backgroundColor: theme.palette.primary[100],
          },
          '& .MuiPickersDay-root:active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,
          },
        },
      },
    },
  };
};
