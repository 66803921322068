
const IconPlan = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="55" cy="55" r="50" fill="#B5DDE1" stroke="black" strokeWidth="10"/>
      <circle cx="36" cy="40" r="8" fill="white"/>
      <circle cx="36" cy="40" r="8" fill="white"/>
      <circle cx="36" cy="40" r="8" fill="white"/>
      <circle cx="74" cy="60" r="8" fill="white"/>
      <circle cx="74" cy="60" r="8" fill="white"/>
      <circle cx="74" cy="60" r="8" fill="white"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M48.2935 40.1468C48.2935 42.8356 47.4199 45.3203 45.941 47.3326L36.108 61.7749L25.979 46.7992H25.982C24.7288 44.8883 24 42.6026 24 40.1468C24 33.4383 29.4383 28 36.1468 28C42.8552 28 48.2935 33.4383 48.2935 40.1468ZM36.2931 45.5616C39.6878 45.5616 42.4397 42.8097 42.4397 39.415C42.4397 36.0204 39.6878 33.2685 36.2931 33.2685C32.8985 33.2685 30.1466 36.0204 30.1466 39.415C30.1466 42.8097 32.8985 45.5616 36.2931 45.5616Z"
        fill="#222121"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M86.2935 60.1468C86.2935 62.8355 85.42 65.3201 83.9412 67.3324L74.108 81.7749L63.979 66.7993H63.982C62.7288 64.8883 62 62.6027 62 60.1468C62 53.4383 67.4383 48 74.1468 48C80.8552 48 86.2935 53.4383 86.2935 60.1468ZM74.2931 65.5616C77.6878 65.5616 80.4397 62.8097 80.4397 59.4151C80.4397 56.0204 77.6878 53.2685 74.2931 53.2685C70.8985 53.2685 68.1466 56.0204 68.1466 59.4151C68.1466 62.8097 70.8985 65.5616 74.2931 65.5616Z"
        fill="#222121"
      />
      <path
        // eslint-disable-next-line max-len
        d="M42.1212 57.1111C45.9448 55.0931 54.293 52.6502 57.0969 59.0228C60.6018 66.9886 35.1114 64.1209 38.6163 75.9103C41.4203 85.3418 60.8143 81.7519 70.1608 78.778"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="1 5"
      />
    </svg>
  );
};

export default IconPlan;