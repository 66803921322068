import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import IconSearch from '../../icons/IconSearch';
import ErrorMessage from '../../layout/error-message/ErrorMessage';
import MergedLabelIconWithLetter from '../merged-label-icon-with-letter/MergedLabelIconWithLetter';
import './AutocompleteComponent.scss';
import { CircularProgress } from '@mui/material';

interface AutocompleteComponentProps {
  isError: boolean;
  errorMessage: string;
  label: string;
  labelIconLetter: string;
  value: any;
  inputValue: string;
  inputPlaceholderText: string;
  inputRef: any;
  inputFocusProgrammatically: boolean | null;
  options: any;
  isLoading: boolean;
  noOptionsText: string;
  ariaLabelPopupIndicatorOpen: string;
  ariaLabelPopupIndicatorClose: string;
  resultFoundMessage: string;
  clearText: string;
  getOptionLabel: (value: any) => string;
  setOptionSelected: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  setInputValue: (event: React.SyntheticEvent<Element, Event>, value: string) => void;
  onBlur: () => void;
  onEscapePressed: () => void;
}

const AutocompleteComponent = ({
  isError,
  errorMessage,
  label,
  labelIconLetter,
  value,
  inputValue,
  inputPlaceholderText,
  inputRef,
  inputFocusProgrammatically,
  options,
  isLoading,
  getOptionLabel,
  setOptionSelected,
  setInputValue,
  onBlur,
  onEscapePressed,
  noOptionsText,
  ariaLabelPopupIndicatorOpen,
  ariaLabelPopupIndicatorClose,
  resultFoundMessage,
  clearText
}: AutocompleteComponentProps) => {
  const [open, setOpen] = useState(false);
  const [oldValueInputFocusProgrammatically, setOldValueInputFocusProgrammatically] = useState(false);

  const handleInputFocus = (event: any) => {
    if (inputFocusProgrammatically !== null && inputFocusProgrammatically != oldValueInputFocusProgrammatically) {
      setOpen(false);
      setOldValueInputFocusProgrammatically(inputFocusProgrammatically);
    } else {
      setOpen(true);
    }
  };

  const highlightMatch = (option: any, inputValueToMatch: string) => {
    const optionLabel = getOptionLabel(option);

    // Normalize both option label and input value by removing accented characters (é --> e) into their base characters and set into lower case
    const normalizedOptionLabel = optionLabel.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    const normalizedInput = inputValueToMatch.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    const index = normalizedOptionLabel.indexOf(normalizedInput);

    // If the entered input value isn't found, we return it as is.
    if (index === -1) return optionLabel;

    // Otherwise, we substring the part that needs to be highlighted and do so in the return
    const beforeHighlight = optionLabel.substring(0, index);
    const highlightedPart = optionLabel.substring(
      index,
      index + inputValueToMatch.length,
    );
    const afterHighlight = optionLabel.substring(index + inputValueToMatch.length);

    return (
      <span>
        {beforeHighlight}
        <strong>{highlightedPart}</strong>
        {afterHighlight}
      </span>
    );
  };

  const handleOnKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      if (open) {
        setOpen(false);
      } else {
        onEscapePressed();
      }
    }
  };

  const isValidOption = (option: any) => { 
    for (const opt of options) {
      if (JSON.stringify(opt) === JSON.stringify(option)) {
        return true;
      }
    }

    return false;
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="Label">
        <MergedLabelIconWithLetter letter={labelIconLetter} /> {label}
      </div>
      <Autocomplete
        role="combobox" // Needed for android mobile accessibility
        value={isValidOption(value) ? value : null} // Check if value is part of the option list toi avoid an error in console
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(event, newInputValue)}
        blurOnSelect={false} // Needed for android mobile accessibility
        open={open} // Needed for iOS VoiceOver accessibility
        onFocus={handleInputFocus} // Needed for iOS VoiceOver accessibility
        onOpen={() => setOpen(true)} // Needed for iOS VoiceOver accessibility
        onClose={() => setOpen(false)} // Needed for iOS VoiceOver accessibility
        onBlur={onBlur}
        aria-haspopup="listbox" // Needed for iOS VoiceOver accessibility
        aria-expanded={open ? 'true' : 'false'} // Needed for iOS VoiceOver accessibility
        clearText={clearText}
        filterOptions={(x) => x}
        options={options}
        getOptionLabel={(option) => {
          // Check if the option is a string meaning the user entered a text and didn't select anything in the suggestion pane
          if (typeof option === 'string') {
            return option;
          }

          // Otherwise, assume it's an option
          return getOptionLabel(option);
        }}
        renderOption={(props, option, state) => (
          // tabIndex at 0 and role="option" here is needed for mobile accessibility
          <li {...props} tabIndex={0} role="option">{highlightMatch(option, state.inputValue)}</li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            className={isError ? 'errorColor' : ''}
            onBlur={undefined} // Needed for iOS VoiceOver accessibility
            onKeyDown={handleOnKeyDown}
            error={isError}
            helperText={
              isError ? <ErrorMessage messageText={errorMessage} /> : ''
            }
            variant="outlined"
            placeholder={inputPlaceholderText}
            inputRef={input => {inputRef.current = input;}}
            InputProps={{
              ...params.InputProps,
              onBlur: undefined, // Needed for iOS VoiceOver accessibility
              startAdornment: <IconSearch />,
              endAdornment: (
                <div>
                  {isLoading ? (
                    <CircularProgress color="primary" size={30} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </div>
              ),
              inputProps: {
                ...params.inputProps,
                'aria-required': 'true',
              }
            }}
          />
        )}
        componentsProps={{
          popupIndicator: {
            'aria-label': open ? ariaLabelPopupIndicatorClose : ariaLabelPopupIndicatorOpen,
            'title': open ? ariaLabelPopupIndicatorClose : ariaLabelPopupIndicatorOpen
          }
        }}
        onChange={setOptionSelected}
        noOptionsText={noOptionsText}
      />
    <p aria-live="assertive" aria-atomic="true" className='accessibilityHideFromView'>
      {resultFoundMessage}
    </p>
    </div>
  );
};

export default AutocompleteComponent;
