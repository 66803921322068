import { red } from '@mui/material/colors';

export const CustomMuiButton = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        //Début Contained
        {
          props: {
            variant: 'contained',
          },
          style: {
            borderRadius: '8px',
            border: 0,
            padding: '14px 24px',
            boxShadow: 'none',
            textTransform: 'none',
            fontWeight: 'bold',
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            letterSpacing: '-0.01em',
          },
        },
        {
          props: {
            variant: 'contained',
            color: 'primary',
          },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.primary[900],
              boxShadow: 'none',
            },
            '&:focus': {
              backgroundColor: theme.palette.primary[900],
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: theme.palette.primary[100],
              color: theme.palette.primary[900],
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: theme.palette.grey[600],
              color: theme.palette.white.main,
              boxShadow: 'none',
            },
          },
        },
        //Fin Contained
        //Début Outlined
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '8px',
            padding: '14px 24px',
            boxShadow: 'inherit',
            textTransform: 'none',
            fontWeight: 'bold',
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid',
            letterSpacing: '-0.01em',
            boxSizing: 'border-box',
          },
        },
        {
          props: {
            variant: 'outlined',
            color: 'primary',
          },
          style: {
            '&:hover': {
              borderColor: theme.palette.primary[900],
              color: theme.palette.primary[900],
              border: '2px solid',
            },
            '&:focus': {
              borderColor: theme.palette.primary[900],
              color: theme.palette.primary[900],
              border: '2px solid',
            },
            '&:active': {
              backgroundColor: theme.palette.primary[100],
              color: theme.palette.primary[900],
              border: '2px solid',
            },
            '&:disabled': {
              color: theme.palette.grey[600],
              borderColor: theme.palette.grey[600],
              border: '2px solid',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
            size: 'small',
          },
          style: {
            minHeight: '32px',
            height: '32px',
          },
        },
        // Fin Outlined
        // Début Text
        {
          props: { variant: 'text' },
          style: {
            border: 0,
            boxShadow: 'inherit',
            textTransform: 'none',
            fontWeight: 'bold',
            fontStyle: 'normal',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            letterSpacing: '-0.01em',
          },
        },
        {
          props: {
            variant: 'text',
            color: 'primary',
          },
          style: {
            '&:hover': {
              color: theme.palette.primary[900],
            },
            '&:focus': {
              backgroundColor: theme.palette.primary[100],
              color: theme.palette.primary[900],
            },
            '&:active': {
              backgroundColor: theme.palette.primary[100],
              color: theme.palette.primary[900],
            },
            '&:disabled': {
              color: theme.palette.grey[600],
              borderColor: theme.palette.grey[600],
            },
          },
        },
        // Fin Text
        {
          props: { size: 'small' },
          style: {
            minHeight: '36px',
            borderRadius: '6px',
            padding: '6px 16px',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '24px',
            textTransform: 'none',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            minHeight: '48px',
            borderRadius: '8px',
            padding: '8px 16px',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '28px',
            textTransform: 'none',
          },
        },
        {
          props: { size: 'large' },
          style: {
            minHeight: '56px',
            borderRadius: '12px',
            padding: '14px 24px',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            textTransform: 'none',
          },
        },

        {
          props: { variant: 'containedError' },
          style: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
          },
        },

        {
          props: { variant: 'dashedError' },
          style: {
            border: `4px dashed ${red[500]}`,
          },
        },
      ],
    },
  };
};
