import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './languageSlice';
import customerReducer from './customerSlice';
import planTripReducer from './planTripSlice';

const store = configureStore({
  reducer: {
    language: languageReducer,
    customer: customerReducer,
    planTrip: planTripReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;