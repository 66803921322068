import { LogLevel } from '@azure/msal-browser';

const clientId = process.env.REACT_APP_B2C_CLIENT_ID ?? '';
const apiClientId = process.env.REACT_APP_WEBAPI_CLIENT_ID ?? '';
const tenantUrl = process.env.REACT_APP_B2C_TENANT_URL ?? '';
const redirectUri = process.env.REACT_APP_B2C_REDIRECT_URI ?? '';
const logOutRedirectUri = process.env.REACT_APP_B2C_LOGOUT_REDIRECT_URI ?? '';
const signInAutority = process.env.REACT_APP_B2C_SIGNIN_AUTHORITY ?? '';
const knownAutority = process.env.REACT_APP_B2C_KNOWN_AUTHORITY ?? '';

const apiIdentifier = `https://${tenantUrl}/${apiClientId}/`;
const readAndWriteApiScope = apiIdentifier + 'ReadAndWrite';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const getMsalConfig = (lang: string) => {
  return {
    auth: {
      clientId,
      authority: signInAutority,
      knownAuthorities: [knownAutority],
      redirectUri: redirectUri.replace('lang', lang),
      postLogoutRedirectUri: logOutRedirectUri.replace('lang', lang),
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      }
    }
  };
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

export const getAccessLoginRequest = (lang: string) => ({
  scopes: [readAndWriteApiScope],
  redirectUri: redirectUri.replace('lang', lang),
  extraQueryParameters: {
    'ui_locales': lang
  }
});

export const getAccessLogoutRequest = (lang: string) => ({
  postLogoutRedirectUri: logOutRedirectUri.replace('lang', lang)
});
