import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: ['fr', 'en'],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en,
      fr,
    },
  });

export default i18n;
