import axios, { AxiosPromise } from 'axios';
import { AddressDto } from '../models/address';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const addressApi = {
  getCustomerRegisteredAddresses(
    customerId: number,
    RegisteredAddrType: string,
    Culture?: string,
  ): AxiosPromise<{ addresses: AddressDto[] }> {
    return axios({
      method: 'GET',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}/registeredAddresses`,
      params: {
        Culture,
        RegisteredAddrType,
      }
    });
  },
  getCustomerServiceAddresses(
    customerId: number,
    Culture?: string,
  ): AxiosPromise<{ addresses: AddressDto[] }> {
    return axios({
      method: 'GET',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}/serviceAddresses`,
      params: {
        Culture,
      }
    });
  },
  getSuggestedAddresses(customerId: number,
    searchText: string,
    excludePredefinedAddresses: boolean,
    useGoogleSearch: boolean,
    Culture?: string
  ): AxiosPromise<{ addresses: AddressDto[] }> {
    return axios({
      method: 'GET',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}/address-suggestions`,
      params: {
        Culture,
        searchText,
        excludePredefinedAddresses,
        useGoogleSearch,
      }
    });
  },
};

export default addressApi;
