import React from 'react';
import './NoAccess.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ServerError from '../../components/alert/server-error/ServerError';

const NoAccess = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const errorMessage = location.state?.errorMessage;

  return (
    <div className="mainContainer">
      <div className="noAccessContainer">
        <ServerError title={t('error.noAccessErrorTitle')}>{errorMessage}</ServerError>
      </div>
    </div>
  );
};

export default NoAccess;