export const CustomMuiChip = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiChip: {
      styleOverrides: {
        filled: {
          '&:hover': {
            backgroundColor: theme.palette.grey[800],
            border: `2px solid ${theme.palette.grey[800]}`,
          },
          '&:active': {
            backgroundColor: theme.palette.grey[800],
            border: `2px solid ${theme.palette.black.main}`,
          },
          '& .MuiChip-deleteIcon': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-deleteIcon:hover': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-iconXsmall': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-iconSmall': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-iconMedium': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-iconLarge': {
            color: theme.palette.white.main,
          },
        },
        outlined: {
          '&:hover': {
            backgroundColor: theme.palette.grey[100],
            border: `2px solid ${theme.palette.grey[800]}`,
          },
          '&:active': {
            backgroundColor: theme.palette.grey[200],
            border: `2px solid ${theme.palette.black.main}`,
          },
          '& .MuiChip-deleteIcon': {
            color: theme.palette.black.main,
          },
          '& .MuiChip-deleteIcon:hover': {
            color: theme.palette.black.main,
          },
          '& .MuiChip-iconXsmall': {
            color: theme.palette.black.main,
          },
          '& .MuiChip-iconSmall': {
            color: theme.palette.black.main,
          },
          '& .MuiChip-iconMedium': {
            color: theme.palette.black.main,
          },
          '& .MuiChip-iconLarge': {
            color: theme.palette.black.main,
          },
        },
        info: {
          '& .MuiChip-deleteIcon': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-deleteIcon:hover': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-iconXsmall': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-iconSmall': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-iconMedium': {
            color: theme.palette.white.main,
          },
          '& .MuiChip-iconLarge': {
            color: theme.palette.white.main,
          },
        },
      },
      variants: [
        {    
          props: {
            size: 'xsmall',
          },
          style: {
            '& .MuiChip-deleteIcon': {
              width: '16px',
              height: '16px',
              margin: '0 -1px 0 -1px',
            },
            '& .MuiChip-iconXsmall': {
              width: '16px',
              height: '16px',
              marginLeft: '0px',
            },
          },
        },
        {    
          props: {
            size: 'small',
          },
          style: {
            '& .MuiChip-deleteIcon': {
              width: '20px',
              height: '20px',
              margin: '0 -1px 0 -1px',
            },
            '& .MuiChip-iconSmall': {
              width: '16px',
              height: '16px',
              marginLeft: '0px',
            },
          },
        },
        {    
          props: {
            size: 'medium',
          },
          style: {
            '& .MuiChip-deleteIcon': {
              width: '20px',
              height: '20px',
              margin: '0 -1px 0 -1px',
            },
            '& .MuiChip-iconMedium': {
              width: '20px',
              height: '20px',
              marginLeft: '0px',
            },
          },
        },
        {    
          props: {
            size: 'large',
          },
          style: {
            '& .MuiChip-deleteIcon': {
              width: '24px',
              height: '24px',
              margin: '0 -1px 0 -1px',
            },
            '& .MuiChip-iconLarge': {
              width: '24px',
              height: '24px',
              marginLeft: '0px',
            },
          },
        },
        {    
          props: {
            variant: 'filled',
            size: 'xsmall',
          },
          style: {
            backgroundColor: theme.palette.black.main,
            border: `2px solid ${theme.palette.black.main}`,
            borderRadius: '12px',
            height: '24px',
            minWidth: '84px',
            alignItems: 'center',
            padding: '2px 6px',
            '& .MuiChip-label': {
              color: theme.palette.white.main,
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '13px',
              lineHeight: '20px',
              '&.MuiChip-root.MuiChip-deleteIcon:hover': {
                color: '#FF0000',
              },
            },
          },
        },
        {    
          props: {
            variant: 'filled',
            size: 'small',
          },
          style: {
            backgroundColor: theme.palette.black.main,
            border: `2px solid ${theme.palette.black.main}`,
            borderRadius: '14px',
            height: '28px',
            minWidth: '84px',
            alignItems: 'center',
            padding: '4px 6px',
            '& .MuiChip-label': {
              color: theme.palette.white.main,
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '13px',
              lineHeight: '20px',
            },
          },
        },
        {    
          props: {
            variant: 'filled',
            size: 'medium',
          },
          style: {
            backgroundColor: theme.palette.black.main,
            border: `2px solid ${theme.palette.black.main}`,
            borderRadius: '20px',
            height: '32px',
            minWidth: '97px',
            alignItems: 'center',
            padding: '4px 8px',
            '& .MuiChip-label': {
              color: theme.palette.white.main,
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '14px',
              lineHeight: '24px',
            },
          },
        },
        {    
          props: {
            variant: 'filled',
            size: 'large',
          },
          style: {
            backgroundColor: theme.palette.black.main,
            border: `2px solid ${theme.palette.black.main}`,
            borderRadius: '20px',
            height: '40px',
            minWidth: '97px',
            alignItems: 'center',
            padding: '8px',
            '& .MuiChip-label': {
              color: theme.palette.white.main,
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
        },
        {    
          props: {
            variant: 'outlined',
            size: 'xsmall',
          },
          style: {
            backgroundColor: theme.palette.white.main,
            borderRadius: '12px',
            borderColor: theme.palette.grey[900],
            borderWidth: '2px',
            height: '24px',
            minWidth: '84px',
            alignItems: 'center',
            padding: '2px 6px',
            '& .MuiChip-label': {
              color: theme.palette.grey[900],
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '13px',
              lineHeight: '20px',
            },
          },
        },
        {    
          props: {
            variant: 'outlined',
            size: 'small',
          },
          style: {
            backgroundColor: theme.palette.white.main,
            borderRadius: '14px',
            borderColor: theme.palette.grey[900],
            borderWidth: '2px',
            height: '28px',
            minWidth: '84px',
            alignItems: 'center',
            padding: '4px 6px',
            '& .MuiChip-label': {
              color: theme.palette.grey[900],
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '13px',
              lineHeight: '20px',
            },
          },
        },
        {    
          props: {
            variant: 'outlined',
            size: 'medium',
          },
          style: {
            backgroundColor: theme.palette.white.main,
            borderRadius: '20px',
            borderColor: theme.palette.grey[900],
            borderWidth: '2px',
            height: '32px',
            minWidth: '97px',
            alignItems: 'center',
            padding: '4px 8px',
            '& .MuiChip-label': {
              color: theme.palette.grey[900],
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '14px',
              lineHeight: '24px',
            },
          },
        },
        {    
          props: {
            variant: 'outlined',
            size: 'large',
          },
          style: {
            backgroundColor: theme.palette.white.main,
            borderRadius: '20px',
            borderColor: theme.palette.grey[900],
            borderWidth: '2px',
            height: '40px',
            minWidth: '97px',
            alignItems: 'center',
            padding: '8px',
            '& .MuiChip-label': {
              color: theme.palette.grey[900],
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
        },
        {    
          props: {
            variant: 'info',
            size: 'xsmall',
          },
          style: {
            backgroundColor: theme.palette.black.main,
            borderRadius: '4px',
            height: '24px',
            alignItems: 'center',
            padding: '2px 6px',
            '& .MuiChip-label': {
              color: theme.palette.white.main,
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '13px',
              lineHeight: '20px',
            },
          },
        },
        {    
          props: {
            variant: 'info',
            size: 'small',
          },
          style: {
            backgroundColor: theme.palette.black.main,
            borderRadius: '6px',
            height: '28px',
            alignItems: 'center',
            padding: '2px 6px',
            '& .MuiChip-label': {
              color: theme.palette.white.main,
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '13px',
              lineHeight: '20px',
            },
          },
        },
        {    
          props: {
            variant: 'info',
            size: 'medium',
          },
          style: {
            backgroundColor: theme.palette.black.main,
            borderRadius: '6px',
            height: '32px',
            alignItems: 'center',
            padding: '4px 8px',
            '& .MuiChip-label': {
              color: theme.palette.white.main,
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '14px',
              lineHeight: '24px',
            },
          },
        },
        {    
          props: {
            variant: 'info',
            size: 'large',
          },
          style: {
            backgroundColor: theme.palette.black.main,
            borderRadius: '8px',
            height: '40px',
            alignItems: 'center',
            padding: '8px',
            '& .MuiChip-label': {
              color: theme.palette.white.main,
              fontWeight: '700',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
        },
      ],      
    },
  };
};
