import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

export interface LanguageState {
  languageInitialCheckDone: {
    value: boolean;
  };
}

// Define the initial state
const initialState: LanguageState = {
  languageInitialCheckDone: {
    value: false,
  }
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguageInitialCheckDone: (state, action: PayloadAction<boolean>) => {
      state.languageInitialCheckDone.value = action.payload;
    }
  },
});

export const { setLanguageInitialCheckDone } = languageSlice.actions;

export const selectLanguageInitialCheckDone = (state: RootState) =>
  state.language.languageInitialCheckDone.value;

export default languageSlice.reducer;
