import React from 'react';
import { useTranslation } from 'react-i18next';
import './NextTripDialog.scss';
import { JourneyDto } from '../../../../models/journey';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog, 
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import TripDetailsClientInfo from '../../common/trip-details-client-info/TripDetailsClientInfo';
import { CustomerDto } from '../../../../models/customer';
import TripDetailsRoute from '../../common/trip-details-route/TripDetailsRoute';
import TripDetailsAssistance from '../../common/trip-details-assistance/TripDetailsAssistance';

interface NextTripDialogProps {
  trip?: JourneyDto,
  isOpened: boolean,
  customer?: CustomerDto,
  onCloseDialog: () => void,
}

const NextTripDialog = ({ trip, isOpened, customer, onCloseDialog }: NextTripDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}    
      maxWidth="sm"
      onClose={onCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpened}
      className="NextTripDialog"
    >
      <DialogTitle id="customized-dialog-title">
        {t(`trips.tripDetails.requestType.${trip?.requestType}`)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCloseDialog}
        sx={{
          position: 'absolute',
          right: 5,
          top: 10,
          color: '#222121',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers tabIndex={0}>
        <>
          {trip && <TripDetailsClientInfo
            client={customer ? `${customer.firstName} ${customer.lastName}` : ''}
            matricule={customer?.identifier}
            date={trip.effectiveDate}
            phone={trip.contactPhone!}
          />}
          {trip && <>
            <hr />
            <TripDetailsRoute
              pickUp={trip.pickUpAddress}
              dropOff={trip.dropOffAddress}
              earliestPickUpDateTime={trip.earliestPickUpDateTime}
              latestPickUpDateTime={trip.latestPickUpDateTime}
              requestedTime={trip.requestedTime}
              requestedTimeType={trip.requestedTimeType}
            />
          </> }
          {trip && <>
            <hr />
            <TripDetailsAssistance assistiveDevices={trip.assistiveDevices} tripPassengers={trip.tripPassengers} />
          </> }
        </>
      </DialogContent>
    </Dialog>
  );
};

export default NextTripDialog;