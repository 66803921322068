import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './app/App';
import { getMsalConfig } from './authConfig';
import store from './store/store';

import { MsalProvider } from '@azure/msal-react';
import './i18n/i18n';
import { getLanguageFromUrl } from './utils/language';
import { BrowserRouter } from 'react-router-dom';

const urlLanguage = getLanguageFromUrl();
const msalConfig = getMsalConfig(urlLanguage);

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const account = (event.payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>  
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </Provider>
    </MsalProvider>
  );
});
