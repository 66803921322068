import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './WarningAlert.scss';

const WarningAlert = (props: any) => {
  const { t } = useTranslation();
  const title = (props.title === '' || props.title === null || props.title === undefined) ? t('error.warningAlertTitle') : props.title;

  return (
    <Alert className="warningAlert" severity="warning" icon={<ErrorRoundedIcon />}>
      <AlertTitle>{title}</AlertTitle>
      {props.children}
    </Alert>
  );
};

export default WarningAlert;