export const CustomMuiDialog = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialogContent-root': {
            padding: '20px',
            borderBottom: 'none'
          },
          '.MuiDialogActions-root': {
            padding: '20px 20px 24px'
          },
        },
        paper: {
          boxShadow: theme.shadows[4],
          borderRadius: '12px',
          maxWidth: '650px',
        },
      },
    },
  };
};
