import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './ServerError.scss';
import { useEffect, useRef } from 'react';

const ServerError = (props: any) => {
  const { t } = useTranslation();
  const title = (props.title === '' || props.title === null || props.title === undefined) ? t('error.serverErrorTitle') : props.title;

  const serverErrorRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (serverErrorRef?.current) {
      serverErrorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverErrorRef]);
  
  return (
    <Alert className="serverError" severity="error" icon={<ErrorRoundedIcon />} ref={serverErrorRef}>
      <AlertTitle>{title}</AlertTitle>
      {props.children}
    </Alert>
  );
};

export default ServerError;