import React from 'react';
import { useTranslation } from 'react-i18next';
import { JourneyTripWithPickUpTime } from '../../../../models/journey';
import './TripDetailsAddresses.scss';
import  dayjs from 'dayjs';

const TripDetailsAddresses = ({ trips }: { trips: JourneyTripWithPickUpTime[] }) => {
  const { t } = useTranslation();

  const formatTime = (date: string) => {
    return dayjs(date).format(t('trips.times.format'));
  };

  return (
    <div className="TripDetailsAddresses">
      <h3 className="TripDetailsAddressesTitle">{t('trips.route.title')}</h3>
      <ul className="TripDetailsAddressesList">
        {trips.map((trip, index) =>
          <li key={index}>
            <div className="TripDetailsAddressesTimes">
              <div>
                <h4 className="TripDetailsAddressesTimesTitle">{t('trips.times.pickup')}</h4>
                <span
                  className="TripDetailsAddressesTimesValue"
                  dangerouslySetInnerHTML={{ __html: t('trips.times.pickupTime', { from: formatTime(trip.earliestPickUpDateTime), to: formatTime(trip.latestPickUpDateTime) }) }}
                />
              </div>
              <div>
                <h4 className="TripDetailsAddressesTimesTitle">{t(`trips.times.typeLong.${trip.requestedTimeType}`)}</h4>
                <span className="TripDetailsAddressesTimesValue">{formatTime(trip.requestedTime)}</span>
              </div>
            </div>
            <div className="TripDetailsAddressesItem">
              <span>{t('trips.tripDetails.from')}{trip.trip.pickUpAddress.longFormattedDesc}</span>
            </div>
            <div className="TripDetailsAddressesItem">
              <span>{t('trips.tripDetails.to')}{trip.trip.dropOffAddress.longFormattedDesc}</span>
            </div>
          </li>,
        )}
      </ul>
    </div>
  );
};

export default TripDetailsAddresses;