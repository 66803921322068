declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    tertiary: true;
  }
}

export const CustomMuiTextField = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiTextField: {
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            padding: 0,
            marginBottom: '15px',
            '.MuiInputBase-root': {
              padding: '0 10px',
              height: '48px',
              border: '1px solid #8F9FA3',
              backgroundColor: '#FFFFFF',
              borderRadius: '8px',
            },
            '.MuiOutlinedInput-root': {},
            '.MuiOutlinedInput-input': {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '28px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
            },

            '.MuiInputLabel-root': {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '28px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
            },

            '.MuiInputLabel-shrink': {
              fontWeight: 700,
              fontSize: '13px',
              lineHeight: '20px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
              top: '10px',
            },

            '&:hover .MuiInputBase-root': {
              border: '1px solid #008199',
              borderRadius: '8px',
            },
            '&:focus-within .MuiInputLabel-root': {
              fontWeight: 700,
              fontSize: '13px',
              lineHeight: '20px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
              top: '10px',
            },
            '&:focus-within .MuiOutlinedInput-input': {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '28px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
              top: '10px',
            },
            '&:focus-within .MuiInputBase-root': {
              boxShadow: '0px 0px 0px 1px #008199',
              borderRadius: '8px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '.MuiFormHelperText-root': {
              marginLeft: 0,
              marginTop: '4px',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
            disabled: true,
          },
          style: {
            '.MuiInputBase-root': {
              border: '1px solid #E3E7E8',
              borderRadius: '8px',
            },
            '.MuiInputLabel-root': {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '28px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: theme.palette.grey[700],
            },
            '&:hover .MuiInputBase-root': {
              backgroundColor: '#FFFFFF',
              border: '1px solid #E3E7E8',
              borderRadius: '8px',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
            error: true,
          },
          style: {
            '.MuiInputBase-root': {
              border: '2px solid',
              borderColor: theme.palette.error.main,
              borderRadius: '8px',
            },
            '.MuiInputLabel-root': {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '28px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
            },
            '.MuiInputLabel-shrink': {
              fontWeight: 700,
              fontSize: '13px',
              lineHeight: '20px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
              top: '10px',
            },
            '&:hover .MuiInputBase-root': {
              backgroundColor: '#F1F3F4',
              border: '2px solid',
              borderColor: theme.palette.error.main,
              borderRadius: '8px',
            },
            '&:focus-within .MuiInputBase-root': {
              opacity: 0.96,
              border: '2px solid #008199',
              borderRadius: '8px',
            },
            '&:focus-within .MuiInputLabel-root': {
              fontWeight: 700,
              fontSize: '13px',
              lineHeight: '20px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
              top: '10px',
            },
            '.MuiFormHelperText-root': {
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '24px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: theme.palette.error.main,
              top: '2px',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
            disabled: true,
            error: true,
          },
          style: {
            '.MuiInputBase-root': {
              borderRadius: '8px',
              border: '1px solid #FFE5E6',
            },
            '.MuiInputLabel-root': {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '28px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: theme.palette.grey[700],
            },
            '&:hover .MuiInputBase-root': {
              backgroundColor: '#FFFFFF',
              opacity: 0.96,
              border: '1px solid #FFE5E6',
              borderRadius: '8px',
            },
          },
        },
        {
          props: {
            className: 'readOnly',
          },
          style: {
            '.MuiInputBase-root': {
              border: '1px solid #E3E7E8',
              borderRadius: '8px',
              backgroundColor: theme.palette.grey[100],
            },
            '&:hover .MuiInputBase-root': {
              border: '1px solid #E3E7E8',
              borderRadius: '8px',
              backgroundColor: theme.palette.grey[100],
            },
            '&:focus-within .MuiInputBase-root': {
              border: '1px solid #E3E7E8',
              borderRadius: '8px',
            },
          },
        },
        {
          props: {
            className: 'readOnly',
            error: true,
          },
          style: {
            '.MuiInputBase-root': {
              border: '1px solid #E3E7E8',
              borderRadius: '8px',
              backgroundColor: theme.palette.grey[100],
            },
            '&:hover .MuiInputBase-root': {
              border: '1px solid #E3E7E8',
              borderRadius: '8px',
              backgroundColor: theme.palette.grey[100],
            },
            '&:focus-within .MuiInputBase-root': {
              border: '1px solid #E3E7E8',
              borderRadius: '8px',
            },
            '.MuiInputLabel-root': {
              fontWeight: 700,
              fontSize: '13px',
              lineHeight: '20px',
              fontFamily: ['Montserrat, sans-serif'].join(','),
              color: '#222121',
              top: '10px',
            },
          },
        },
        // {
        //   props: {
        //     className: "startAdornment",
        //   },
        //   style: {
        //     ".MuiInputLabel-root": {
        //       fontWeight: 400,
        //       fontSize: "24px",
        //       lineHeight: "28px",
        //       fontFamily: ["Montserrat, sans-serif"].join(","),
        //       color: "#222121",
        //       top:"28px",
        //       left: "30px",
        //     },
        //     "&:focus-within .MuiInputLabel-root": {
        //       fontWeight: 700,
        //       fontSize: "13px",
        //       lineHeight: "20px",
        //       fontFamily: ["Montserrat, sans-serif"].join(","),
        //       color: "#222121",
        //       top: "10px",
        //     },
        //   },
        // },
        // {
        //   props: {
        //     className: "startAdornment",
        //     error: true,
        //   },
        //   style: {
        //     ".MuiInputLabel-root": {
        //       fontWeight: 400,
        //       fontSize: "24px",
        //       lineHeight: "28px",
        //       fontFamily: ["Montserrat, sans-serif"].join(","),
        //       color: "#222121",
        //       top:"28px",
        //       left: "30px",
        //     },
        //     "&:focus-within .MuiInputLabel-root": {
        //       fontWeight: 700,
        //       fontSize: "13px",
        //       lineHeight: "20px",
        //       fontFamily: ["Montserrat, sans-serif"].join(","),
        //       color: "#222121",
        //       top: "10px",
        //     },

        //   },
        // },
      ],
    },
    MuiFormHelperText: {
      style: {
        marginLeft: 0,
      },
    },

    // MuiInputLabel: {
    //     styleOverrides: {
    //         root: {
    //             fontWeight: 400,
    //             fontSize: '18px',
    //             lineHeight: '28px',
    //             fontFamily: ['Montserrat, sans-serif'].join(','),
    //             color: '#222121',
    //         },
    //         shrink: {
    //             fontWeight: 700,
    //             fontSize: '13px',
    //             lineHeight: '20px',
    //             fontFamily: ['Montserrat, sans-serif'].join(','),
    //             color: '#222121',
    //             top: '10px',
    //         },
    //     },
    // },
    // MuiOutlinedInput: {
    //     styleOverrides: {
    //         notchedOutline: {
    //             border: 'none',
    //         },
    //     },
    // },
  };
};
