import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestedTimeType } from '../../../../models/journey';
import  dayjs from 'dayjs';
import './TripTimes.scss';

interface TripTimesProps {
  earliestPickUpDateTime: string,
  latestPickUpDateTime: string,
  requestedTime: string,
  requestedTimeType?: RequestedTimeType
}

const TripTimes = ({
  earliestPickUpDateTime,
  latestPickUpDateTime,
  requestedTime,
  requestedTimeType,
}: TripTimesProps) => {
  const { t } = useTranslation();

  const formatTime = (date: string) => {
    return dayjs(date).format(t('trips.times.format'));
  };

  return (
    <div className="TripTimes">
      <div>
        <h4 className="TripTimesTitle">{t('trips.times.pickup')}</h4>
        <span
          className="TripTimesValue"
          dangerouslySetInnerHTML={{ __html: t('trips.times.pickupTime', { from: formatTime(earliestPickUpDateTime), to: formatTime(latestPickUpDateTime) }) }}
        />
      </div>
      <div>
        <h4 className="TripTimesTitle">{t(`trips.times.type.${requestedTimeType}`)}</h4>
        <span className="TripTimesValue">{formatTime(requestedTime)}</span>
      </div>
    </div>
  );
};

export default TripTimes;