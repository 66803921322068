export const CustomMuiDialogTitle = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          lineHeight: '28px',
          fontStyle: 'normal',
          fontSize: '18px',
          color: theme.palette.black.main,
          padding: '16px 45px 16px 20px !important',
          '&.centerTitle' : {
            textAlign: 'center',
          },
        },
      },
    },
  };
};