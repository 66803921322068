import { t } from 'i18next';
import './Footer.scss';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="container-fluid">
        <p className="Footer__text"> {t('footer.text', { year: new Date().getFullYear() })}</p>
        <nav className="Footer__nav">
          <ul>
            <li><a href={t('footer.privacy-url')}>{t('footer.privacy')} <OpenInNewIcon /></a></li>
            <li><a href={t('footer.term-and-conditions-url')}>{t('footer.term-and-conditions')} <OpenInNewIcon /></a></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;