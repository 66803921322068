import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import './NextTripCard.scss';
import { JourneyDto } from '../../../../models/journey';
import { capitalize } from '../../../../utils/text';
import TripRequestType from '../../common/trip-request-type/TripRequestType';
import TripRoute from '../../common/trip-route/TripRoute';
import TripTimes from '../../common/trip-times/TripTimes';
import { Button } from '@mui/material';

interface NextTripCardProps {
  trip: JourneyDto,
  onTripDetails: (trip: JourneyDto) => void,
  onCancelTrip: (trip: JourneyDto) => void
}

const NextTripCard = ({ trip, onTripDetails, onCancelTrip }: NextTripCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="TripsListItem">
      <div className="TripsListItemHeader">
        <div className="TripsListItemHeaderLeft">
          <TripRequestType requestType={trip.requestType} />
        </div>
        <div className="TripsListItemHeaderRight">
          <h3>{capitalize(dayjs(trip.effectiveDate).format(t('trips.tripCard.dateFormat')))}</h3>
        </div>
      </div>
      <div className="TripsListItemBody">
        <TripRoute pickUp={trip.pickUpAddress} dropOff={trip.dropOffAddress} />
        <TripTimes
          earliestPickUpDateTime={trip.earliestPickUpDateTime}
          latestPickUpDateTime={trip.latestPickUpDateTime}
          requestedTime={trip.requestedTime}
          requestedTimeType={trip.requestedTimeType}
        />
      </div>
      <div className="TripsListItemActions TripsListItemActionsNextTrips">
        <Button
          variant="outlined"
          size="small"
          onClick={() => onTripDetails(trip)}
        >{t('trips.tripCard.details')}</Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => onCancelTrip(trip)}
        >{t('trips.tripCard.cancelTrip')}</Button>
      </div>
    </div>
  );
};

export default NextTripCard;