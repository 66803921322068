import axios, { AxiosPromise } from 'axios';
import dayjs from 'dayjs';
import { TripAssistiveDeviceJourneyDto } from '../models/device';
import { FormPlanTrip } from '../models/form-plan-trip';
import { JourneyDto } from '../models/journey';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const journeyApi = {
  getCustomerJourneys(
    customerId: string,
    ScheduleType?: string,
    JourneyType?: string,
    Culture?: string,
  ): AxiosPromise<{ journeys: JourneyDto[] }> {
    return axios({
      method: 'GET',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}/journeys`,
      params: {
        Culture,
        ScheduleType,
        JourneyType,
      }
    });
  },
  planJourneys(
    customerId: number,
    Form: FormPlanTrip,
    AttendantMandatory: boolean | undefined,
    Culture?: string
  ): AxiosPromise<{ journeys: Array<JourneyDto> }> {
    const requestedDateTime = dayjs(Form.Date)
      .set('hour', dayjs(Form.Time).hour())
      .set('minute', dayjs(Form.Time).minute())
      .set('second', 0);

    return axios({
      method: 'POST',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}/journeys/planJourneys`,
      params: {
        Culture,
      },
      data: [
        {
          origin: {
            addressId: Form.PointA?.addressLocation.addressIdentifier,
            // registeredAddressType: "Home",
            civicNumber: Form.PointA?.addressLocation.civicNumber,
            street: Form.PointA?.addressLocation.streetName,
            municipalityName: Form.PointA?.addressLocation.municipalityName,
            isServiceAddress: Form.PointA?.addressLocation.isServiceAddress,
            longitude: Form.PointA?.addressLocation.longitude,
            latitude: Form.PointA?.addressLocation.latitude,
            registeredAddressType: Form.PointA?.addressLocation?.registeredAddressType,
          },
          destination: {
            addressId: Form.PointB?.addressLocation.addressIdentifier,
            // registeredAddressType: "Null",
            civicNumber: Form.PointB?.addressLocation.civicNumber,
            street: Form.PointB?.addressLocation.streetName,
            municipalityName: Form.PointB?.addressLocation.municipalityName,
            isServiceAddress: Form.PointB?.addressLocation.isServiceAddress,
            longitude: Form.PointB?.addressLocation.longitude,
            latitude: Form.PointB?.addressLocation.latitude,
            registeredAddressType: Form.PointB?.addressLocation.registeredAddressType,
          },
          requestedDateTime: requestedDateTime.format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          requestedTimeType: Form.HourType.toString(),
          dayType: requestedDateTime.locale('en').format('dddd'),
          assistiveDevices: Form.AssistiveDevices?.map(
            (item: string): TripAssistiveDeviceJourneyDto => {
              return {
                id: item,
                numberForCustomer: 1,
                totalNumber: 1,
                description: item,
              };
            }
          ),
          tripPassengers: AttendantMandatory
            ? ['Customer', 'Attendant']
            : ['Customer'],
        },
      ]
    });
  },
  cancelJourney(customerId: string, scheduleId: string, journeyId: string, Culture?: string): AxiosPromise<void> {
    return axios({
      method: 'PUT',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}/journeys/cancelJourney`,
      params: { Culture },
      data: {
        scheduleId,
        journeyId
      }
    });
  },
  saveJourney(
    customerId: number,
    journey: JourneyDto,
    Culture?: string
  ): AxiosPromise<{ journeys: Array<JourneyDto> }> {
    return axios({
      method: 'POST',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}/journeys/saveJourneys`,
      params: {
        Culture,
      },
      data: [journey],
    });
  },
  confirmJourney(
    customerId: number,
    scheduleId: string,
    journeyId: string,
    Culture?: string
  ): AxiosPromise<{ journeys: Array<JourneyDto> }> {
    return axios({
      method: 'PUT',
      baseURL: API_BASE_URL,
      url: `/customers/${customerId}/journeys/confirmJourney`,
      params: {
        Culture,
      },
      data: {
        scheduleId: scheduleId,
        journeyId: journeyId,
      }
    });
  },
};

export default journeyApi;
