import { TripAssistiveDeviceJourneyDto } from './device';
import { DayOfWeek, JourneyAddressDto, JourneyDto, JourneyTripWithPickUpTime, RequestType } from './journey';

export interface GroupedJourneyItem {
  days: DayOfWeek[];
  pickUpAddress: JourneyAddressDto;
  dropOffAddress: JourneyAddressDto;
  requestType?: RequestType;
  requestStartDate: string;
  requestEndDate: string;
  frequency: number;
  contactPhone?: string;
  creationDate: string;
  assistiveDevices?: TripAssistiveDeviceJourneyDto[];
  tripPassengers?: string[];
  trips: JourneyTripWithPickUpTime[];
}

export class GroupedJourney {
  groupedJourneys: GroupedJourneyItem[] = [];

  constructor(journeys: JourneyDto[]) {
    if (journeys) {
      this.groupedJourneys = this.getGroupedJourneyItems(journeys);
    }
  }

  mapJourneysById(journeys: JourneyDto[]): { [key: string]: JourneyDto[] } {
    return journeys.reduce((acc: { [key: string]: JourneyDto[] }, journey: JourneyDto) => {
      const key = journey.key.journeyId?.substring(0, journey.key.journeyId.length - 2);
      if (key) {
        if (!acc[key]) acc[key] = [];
        acc[key].push(journey);
      }
      return acc;
    }, {});
  }

  getDays(group: JourneyDto[]): DayOfWeek[] {
    const days: DayOfWeek[] = [];
    group.forEach((journey) => {
      if (!days.includes(journey.dayType)) {
        days.push(journey.dayType);
      }
    });
    return days;
  }

  getTrips(group: JourneyDto[]): JourneyTripWithPickUpTime[] {
    const trips: JourneyTripWithPickUpTime[] = [];
    group.forEach((journey) => {
      if (journey.trips) {
        trips.push({
          earliestPickUpDateTime: journey.earliestPickUpDateTime,
          latestPickUpDateTime: journey.latestPickUpDateTime,
          requestedTime: journey.requestedTime,
          requestedTimeType: journey.requestedTimeType,
          trip: journey.trips[0],
        });
      }
    });
    return trips;
  } 

  mapGroupedJourneyitems(groupedJourneys: JourneyDto[][]): GroupedJourneyItem[] {
    return groupedJourneys.map((group): GroupedJourneyItem => {
      return {
        days: this.getDays(group),
        pickUpAddress: group[0].pickUpAddress,
        dropOffAddress: group[0].dropOffAddress,
        requestStartDate: group[0].requestStartDate,
        requestEndDate: group[0].requestEndDate,
        requestType: group[0].requestType,
        frequency: group[0].frequency,
        contactPhone: group[0].contactPhone,
        creationDate: group[0].effectiveDate,
        tripPassengers: group[0].tripPassengers,
        assistiveDevices: group[0].assistiveDevices,
        trips: this.getTrips(group),
      };
    });
  }

  getGroupedJourneyItems(journeys: JourneyDto[]): GroupedJourneyItem[] {
    const groupedJourneysMap: { [key: string]: JourneyDto[] } = this.mapJourneysById(journeys);
    return this.mapGroupedJourneyitems(Object.values(groupedJourneysMap));
  }
}
