import inst, { PluginFunc } from 'dayjs';

declare module 'dayjs' {
  interface Dayjs {
    round5min(): inst.Dayjs;
  }
}
const round5min: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.round5min = function () {
    return this.add(5 - (this.minute() % 5), 'minute').startOf('minute');
  };
};
export default round5min;
