import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, AlertTitle } from '@mui/material';
import './ServerSuccess.scss';
import { useEffect, useRef } from 'react';

const ServerSuccess = (props: any) => {

  const serverSucessRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (serverSucessRef?.current) {
      serverSucessRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverSucessRef]);

  return (
    <Alert className="ServerSuccess" severity="success" icon={<CheckCircleIcon />} onClose={props.onClose} ref={serverSucessRef} >
      <AlertTitle>{props.title}</AlertTitle>
      {props.children}
    </Alert>
  );
};

export default ServerSuccess;