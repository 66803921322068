import LabelIcon from '@mui/icons-material/Label';
import './MergedLabelIconWithLetter.scss';

interface MergedLabelIconWithLetterProps {
  letter: string;
}

const MergedLabelIconWithLetter = ({
  letter,
}: MergedLabelIconWithLetterProps) => {
  return (
    <div className="labelIconWithLetterContainer" aria-hidden={true}>
      <LabelIcon className="labelIcon" />
      <div className="positionLetter">{letter}</div>
    </div>
  );
};

export default MergedLabelIconWithLetter;
