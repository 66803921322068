import React from 'react';
import { useTranslation } from 'react-i18next';
import { JourneyAddressDto } from '../../../../models/journey';
import MergedLabelIconWithLetter from '../../../merged-label-icon-with-letter/MergedLabelIconWithLetter';
import './TripRoute.scss';

const TripRoute = ({ pickUp, dropOff }: { pickUp: JourneyAddressDto, dropOff: JourneyAddressDto }) => {
  const { t } = useTranslation();

  return (
    <div className="TripRoute">
      <h4 className="TripRouteTitle">{t('trips.route.title')}</h4>
      <div className="TripRouteItem">
        <div className="TripRouteItemIcon">
          <MergedLabelIconWithLetter letter="A" />
        </div>
        <div className="TripRouteItemDesc">
          <span>{pickUp?.shortFormattedDesc}</span>
        </div>
      </div>
      <div className="TripRouteItem">
        <div className="TripRouteItemIcon">
          <MergedLabelIconWithLetter letter="B" />
        </div>
        <div className="TripRouteItemDesc">
          <span>{dropOff?.shortFormattedDesc}</span>
        </div>
      </div>
    </div>
  );
};

export default TripRoute;