import React from 'react';
import { useTranslation } from 'react-i18next';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { RequestType } from '../../../../models/journey';
import './TripRequestType.scss';

const TripRequestType = ({ requestType }: { requestType?: RequestType }) => {
  const { t } = useTranslation();

  return (
    <>
      { requestType &&
        <div className={`TripRequestType TripRequestType${requestType}`}>
          <DirectionsBusIcon className="TripRequestTypeIcon" />
          <span>{t(`trips.requestType.${requestType}`)}</span>
        </div>
      }
    </>
  );
};

export default TripRequestType;