import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ErrorListener = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleMaintenanceEvent = (event: any) => {
    navigate('/' + i18n.resolvedLanguage + '/maintenance', { state: { errorMessage: event.detail } }); 
  };

  const handleNoAccessEvent = (event: any) => {
    navigate('/' + i18n.resolvedLanguage + '/noAccess', { state: { errorMessage: event.detail } }); 
  };

  useEffect(() => {   
    window.addEventListener('maintenance', handleMaintenanceEvent);
    window.addEventListener('noAccess', handleNoAccessEvent);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener('maintenance', handleMaintenanceEvent);
      window.removeEventListener('noAccess', handleMaintenanceEvent);
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

export { ErrorListener };
