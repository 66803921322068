import { ArrowCircleLeft, ArrowCircleRight, Info } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormLabel, IconButton, Paper, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/plugin/isSameOrAfter';
import 'dayjs/plugin/isSameOrBefore';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconCalendar from '../../../icons/IconCalendar';
import IconWatch from '../../../icons/IconWatch';
import ErrorMessage from '../../../layout/error-message/ErrorMessage';
import { HourTypeChoice } from '../../../models/form-plan-trip';
import { selectFormPlanTrip, setFormPlanTripValue } from '../../../store/planTripSlice';
import round5min from '../../../utils/round';
import PlanTripButtons from '../plan-trip-buttons/PlanTripButtons';
import './PlanTripStep2.scss';import TagManager from 'react-gtm-module';
 
TagManager.dataLayer({
  dataLayer: {
    event: 'planTrip',
    step: 2,
  }
});

const PlanTripStep2 = () => {
  dayjs.extend(round5min);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formPlanTrip = useSelector(selectFormPlanTrip);
  const [dateErrorText, setDateErrorText] = React.useState('');
  const [timeErrorText, setTimeErrorText] = React.useState('');
  const [hasHourTypeChanged, setHasHourTypeChanged] = React.useState(false);
  const [skipCall, setSkipCall] = React.useState(true);
  const datePickerRef = useRef<HTMLInputElement | null>(null);
  const timePickerRef = useRef<HTMLInputElement | null>(null);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const calendarOpenRef = useRef(calendarOpen);
  const [timeOpen, setTimeOpen] = React.useState(false);
  const timeOpenRef = useRef(timeOpen);
  const [screenReaderText, setScreenReaderText] = React.useState('');
  const [toolTipOpen, setToolTipOpen] = React.useState(false);

  useEffect(() => {
    // Since page rendering will trigger the useEffect, skip the call that is generated by it. The following ones will go through as usual
    if (skipCall) {
      setSkipCall(false);
      return;
    }

    validateFields('tripTime');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasHourTypeChanged]);

  useEffect(() => {
    calendarOpenRef.current = calendarOpen;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarOpen]);

  useEffect(() => {
    timeOpenRef.current = timeOpen;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOpen]);

  useEffect(() => {
    // The reference is set to the input from datepicker. As such, the icon is a sibling of the refence so we find the parent element
    // and then the button found within the sibling of the reference
    const parentElement = datePickerRef.current?.parentElement;
    const calendarIcon = parentElement?.querySelector('button');

    if (calendarIcon) {
      const handleMouseEnter = () => {
        if (calendarOpenRef.current) {
          setScreenReaderText(t('plan-trip.step2.calendarCloseIconText'));
        } else {
          setScreenReaderText(t('plan-trip.step2.calendarOpenIconText'));
        }
      };

      const handleMouseLeave = () => {
        setScreenReaderText('');
      };

      calendarIcon.addEventListener('mouseenter', handleMouseEnter);
      calendarIcon.addEventListener('mouseleave', handleMouseLeave);

      // Cleanup event listeners on unmount
      return () => {
        calendarIcon.addEventListener('mouseenter', handleMouseEnter);
        calendarIcon.addEventListener('mouseleave', handleMouseLeave);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerRef.current]);

  useEffect(() => {
    // The reference is set to the input from timePicker. As such, the icon is a sibling of the refence so we find the parent element
    // and then the button found within the sibling of the reference
    const parentElement = timePickerRef.current?.parentElement;
    const timeIcon = parentElement?.querySelector('button');

    if (timeIcon) {
      const handleMouseEnter = () => {
        if (timeOpenRef.current) {
          setScreenReaderText(t('plan-trip.step2.timeCloseIconText'));
        } else {
          setScreenReaderText(t('plan-trip.step2.timeOpenIconText'));
        }
      };

      const handleMouseLeave = () => {
        setScreenReaderText('');
      };

      timeIcon.addEventListener('mouseenter', handleMouseEnter);
      timeIcon.addEventListener('mouseleave', handleMouseLeave);

      // Cleanup event listeners on unmount
      return () => {
        timeIcon.addEventListener('mouseenter', handleMouseEnter);
        timeIcon.addEventListener('mouseleave', handleMouseLeave);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePickerRef.current]);

  const handleToolTipToggle = () => {
    setToolTipOpen(!toolTipOpen);
  };

  const shouldDisableDate = (date: Dayjs) => {
    const today = dayjs();
    const next14Days = today.add(14, 'day');
    return date.isBefore(today) || date.isAfter(next14Days);
  };
  

  const shouldDisableTime = (value:any, view:any) => {
    const form = { ...formPlanTrip };

    // For arrival type hours, all can be selected except 1h to 5h. Midnight can be selected
    if (view === 'hours' && form.HourType === HourTypeChoice.Arrival) {
      return  value.hour() != 0 && value.hour() < 6;
    }

    // For departure type hours, all can be selected except midnight (0h) to 5h
    if (view === 'hours' && form.HourType === HourTypeChoice.Departure) {
      return  value.hour() < 6;
    }

    // For arrival type minutes, all can be selected except 0 to 30 at 6h and only 0 for midnight. Also make sure minutes is disabled for all disabled hours
    if (view === 'minutes' && form.HourType === HourTypeChoice.Arrival) {
      return  (value.hour() === 6 && value.minute() < 30) || (value.hour() === 0 && value.minute() != 0) || (value.hour() >= 1 && value.hour() <= 5 && value.minute() >= 0 );
    }

    // For departure type minutes, all can be selected except 0 to 30 at 6h and only 0 for 23h. Also make sure minutes is disabled for all disabled hours
    if (view === 'minutes' && form.HourType === HourTypeChoice.Departure) {
      return  (value.hour() === 6 && value.minute() < 30) || (value.hour() === 23 && value.minute() != 0) || (value.hour() >= 0 && value.hour() <= 5 && value.minute() >= 0 );
    }

    return false; // Should not go there but if it does, leave all enabled
  };
  
  const validateFields = (fieldName: string):boolean => {
    const form = { ...formPlanTrip };
    let isDateValid = true;
    let isTimeValid = true;

    switch (fieldName) {
      case 'tripTime':
        setTimeErrorText('');

        if (form.Time === '') {
          setTimeErrorText(t('shared.errors.time-void'));
          isTimeValid = false;
        } else {
          const selectedTime = dayjs(form.Time);
          const selectedHour = selectedTime.hour();
          const selectedMinute = selectedTime.minute();
          let hourInvalid = false;
          let minuteInvalid = false;

          if (form.HourType === HourTypeChoice.Arrival) {
            hourInvalid = selectedHour < 6 && selectedHour != 0;
            minuteInvalid = (selectedHour == 6 && selectedMinute < 30) || (selectedHour == 0 && selectedMinute != 0);

            isTimeValid = !(hourInvalid || minuteInvalid);
          } else if (form.HourType === HourTypeChoice.Departure) {
            hourInvalid = selectedHour < 6;
            minuteInvalid = (selectedHour == 6 && selectedMinute < 30) || (selectedHour == 23 && selectedMinute != 0);

            isTimeValid = !(hourInvalid || minuteInvalid);
          }

          if (!isTimeValid)
            setTimeErrorText(t('shared.errors.time-deadline'));
        }

        dispatch(setFormPlanTripValue(form));
        return isTimeValid;
      case 'tripDate':
        setDateErrorText('');

        if (form.Date === '') {
          setDateErrorText(t('shared.errors.date-void'));
          isDateValid = false;
        } else if (shouldDisableDate(dayjs(form.Date))) {
          setDateErrorText(t('shared.errors.date-out-range'));
          isDateValid = false;
        }

        dispatch(setFormPlanTripValue(form));
        return isDateValid;
      default:
        return false;
    }
  };

  const handleOnChangeDate = ( date: Dayjs | any ) => {
    const form = { ...formPlanTrip };
    if (dayjs(date).isValid()) {
      form.Date = (date as Dayjs).toISOString();
      setDateErrorText('');
      dispatch(setFormPlanTripValue(form));
    } else {
      form.Date = '';
    }
  };

  const handleOnChangeHourType = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const form = { ...formPlanTrip };
    form.HourType = value as HourTypeChoice; 
    dispatch(setFormPlanTripValue(form));
    setHasHourTypeChanged(!hasHourTypeChanged);
  };

  const handleOnChangeTime = (value: Dayjs | null) => {
    const form = { ...formPlanTrip };
    if (dayjs(value).isValid()) {
      const tripTime = value as Dayjs;
      form.Time = tripTime.toISOString();
      setTimeErrorText('');
      dispatch(setFormPlanTripValue(form));
    } else {
      form.Time = '';
      dispatch(setFormPlanTripValue(form));
    }
  };

  const dateOnBlurHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    validateFields(event.target.id);
  };

  const timeOnblurHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    validateFields(event.target.id);
  };

  const handleSubmitForm = () : boolean => {
    const isDateValid = validateFields('tripDate');

    let focusDone = false;
    if (!isDateValid && datePickerRef.current !== null) {
      datePickerRef.current.focus();
      focusDone = true;
    }

    const isTimeValid = validateFields('tripTime');

    if (!focusDone && !isTimeValid && timePickerRef.current !== null) {
      timePickerRef.current.focus();
      focusDone = true;
    }

    return  isDateValid && isTimeValid;
  };

  return (
    <div className="PlanTripStep2">
      <Typography variant="h1" component="h1" className="title-step">
        {t('plan-trip.step2.title')}
      </Typography>
      <Typography variant="subtitle1" component="h3" className="sub-title-step">
        {t('plan-trip.step2.stepDescription')}
      </Typography>

      {/* Select date  */}
      <FormControl color={!!dateErrorText.trim() ? 'primary' : 'error'} className="container">
        <FormLabel className="label-field-with-format">
          {t('plan-trip.step2.dateSelectLabel')}{' '}
          <span>{t('plan-trip.step2.dateSelectLabelFormat')}</span>
        </FormLabel>

        <DesktopDatePicker
          className="field date-picker"
          onChange={handleOnChangeDate}
          defaultValue={!!formPlanTrip.Date ? dayjs(formPlanTrip.Date) : null}
          open={calendarOpen}
          onOpen={() => setCalendarOpen(true)}
          onClose={() => setCalendarOpen(false)}
          slots={{
            openPickerIcon: IconCalendar,
            leftArrowIcon: ArrowCircleLeft,
            rightArrowIcon: ArrowCircleRight,
          }}
          shouldDisableDate={(date: Dayjs) => { return shouldDisableDate(date); }}
          slotProps={{
            openPickerButton: {
              color: 'primary',
            },
            inputAdornment: {
              position: 'end',
            },
            textField: {
              inputRef: datePickerRef,
              id: 'tripDate',
              fullWidth: true,
              inputProps: {
                placeholder: t('shared.field.date-placeholder'),
                'aria-required': 'true',
              },
              error: !!dateErrorText.trim(),
              helperText: dateErrorText ? (
                <ErrorMessage messageText={dateErrorText} />
              ) : (
                ''
              ),
              onBlur: dateOnBlurHandler,
            },
          }}
        />
      </FormControl>

      {/* Select hour  */}
      <FormControl color={!!timeErrorText.trim() ? 'primary' : 'error'}>
        <div className="label-container">
          <FormLabel className="label-field-with-format">
            {t('plan-trip.step2.timeSelectLabel')}{' '}
            <span>{t('plan-trip.step2.timeSelectLabelFormat')}</span>
          </FormLabel>
          <Tooltip title={t('plan-trip.step2.timeTooltipText')} open={toolTipOpen} placement="top" arrow>
            <IconButton color="primary" onClick={handleToolTipToggle}>
              <Info />
            </IconButton>
          </Tooltip>
        </div>

        <Paper elevation={0} square className="bussiness-hours-container">
          <Typography variant="subtitle1" component="h6" className="title">
            {t('plan-trip.step2.timeServiceWarningTitle')}
          </Typography>
          <Typography className="text">
            {t('plan-trip.step2.timeServiceWarningText1')}
          </Typography>
          <Typography className="text">
            {t('plan-trip.step2.timeServiceWarningText2')}
          </Typography>
        </Paper>

        <FormControl className="radio-group-container field">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue={HourTypeChoice.Arrival}
            onChange={handleOnChangeHourType}
            value={formPlanTrip.HourType}
          >
            <FormControlLabel
              value={HourTypeChoice.Arrival}
              control={<Radio />}
              className="label-radio"
              label={t('plan-trip.step2.optinArriveBy')}
            />
            <FormControlLabel
              value={HourTypeChoice.Departure}
              control={<Radio />}
              className="label-radio"
              label={t('plan-trip.step2.optinDepartAt')}
            />
          </RadioGroup>
        </FormControl>

        <DesktopTimePicker
          className="field time-picker"
          value={!!formPlanTrip.Time ? dayjs(formPlanTrip.Time) : null}
          onChange={handleOnChangeTime}
          open={timeOpen}
          onOpen={() => setTimeOpen(true)}
          onClose={() => setTimeOpen(false)}
          minutesStep={5}
          shouldDisableTime={shouldDisableTime}
          ampm={false}
          slots={{
            openPickerIcon: IconWatch,
            nextIconButton: ArrowCircleRight,
            previousIconButton: ArrowCircleLeft,
            leftArrowIcon: ArrowCircleLeft,
            rightArrowIcon: ArrowCircleRight,
          }}
          slotProps={{
            textField: {
              inputRef: timePickerRef,
              id: 'tripTime',
              error: !!timeErrorText.trim(),
              helperText: timeErrorText ? (<ErrorMessage messageText={timeErrorText} />) : (''),
              onBlur: timeOnblurHandler,
              fullWidth: true,
              inputProps: {
                placeholder: t('shared.field.time-placeholder'),
                'aria-required': 'true',
              },
            },
            openPickerButton: {
              color: 'primary',
            },

            inputAdornment: {
              position: 'end',
            },
          }}
        />
      </FormControl>

      <PlanTripButtons handleSubmit={handleSubmitForm}></PlanTripButtons>

      <div aria-live="polite" className='sr-only'>
          {screenReaderText}
      </div>
    </div>
  );
};

export default PlanTripStep2;
