import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PageWrapper from '../../layout/page-wrapper/PageWrapper';
import { CustomerAssistiveDeviceDto, CustomerEmailDto, CustomerPhoneDto } from '../../models/customer';
import { selectCustomer } from '../../store/customerSlice';
import './Profil.scss';

const Profil = () => {
  const { t } = useTranslation();
  const customer = useSelector(selectCustomer);

  const getDefaultEmailAddress = (emails?: CustomerEmailDto[]): string => {
    if (emails && emails.length > 0) {
      return emails.find((email) => email.isDefault)?.email ?? '';
    }
    return '';
  };

  const getPhoneNumberString = (phone: CustomerPhoneDto): string => {
    let phoneNumber = phone.phoneInfo.phoneNumber ?? '';
    if (phone.phoneInfo.phoneExtansion) {
      phoneNumber = `${phoneNumber} ext.: ${phone.phoneInfo.phoneExtansion}`;
    }
    return phoneNumber;
  };

  const getDefaultPhoneNumber = (phones?: CustomerPhoneDto[]): string => {
    if (phones && phones.length > 0) {
      const defaultPhone = phones.find((phone) => phone.isDefault);
      return defaultPhone ? getPhoneNumberString(defaultPhone) : '';
    }
    return '';
  };

  const hasAssitiveDevices = (assistiveDevices?: CustomerAssistiveDeviceDto[]): boolean => {
    return !!assistiveDevices && assistiveDevices.length > 0;
  };

  return (
    <PageWrapper title={t('profil.title')}>
      { customer && <div className="Profil">
        <p className="ProfilIntro" dangerouslySetInnerHTML={{ __html: t('profil.intro') }}></p>
        <div className="ProfilInformations">
          <div>
            <h2 className="ProfilSubtitle">{t('profil.name')}</h2>
            <span className="ProfilValue">{customer.firstName} {customer.lastName}</span>
          </div>
          <div>
            <h2 className="ProfilSubtitle">{t('profil.matricule')}</h2>
            <span className="ProfilValue">{customer.identifier}</span>
          </div>
          <div>
            <h2 className="ProfilSubtitle">{t('profil.email')}</h2>
            <span className="ProfilValue">{getDefaultEmailAddress(customer.emails)}</span>
          </div>
          <div>
            <h2 className="ProfilSubtitle">{t('profil.phone')}</h2>
            <span className="ProfilValue">{getDefaultPhoneNumber(customer.phoneNumbers)}</span>
          </div>
          <div>
            <h2 className="ProfilSubtitle">{t('profil.mainAddress')}</h2>
            <span className="ProfilValue">{customer.mailAddress}</span>
          </div>
          <div>
            <h2 className="ProfilSubtitle">{t('profil.preferredLanguage')}</h2>
            <span className="ProfilValue">{customer.communicationLanguage}</span>
          </div>
          <div>
            <h2 className="ProfilSubtitle">{t('profil.assistiveDevices')}</h2>
            <span className="ProfilValue">
              {hasAssitiveDevices(customer.assistiveDevices) ?
                customer?.assistiveDevices?.map((item, index) => (
                  <div id={item?.assistiveDevice.identifier} key={item?.assistiveDevice.identifier} className='Profile-assistiveDeviceItem'>
                    {item?.assistiveDevice.description} 
                  </div>
                )) : ''
              }
            </span>
          </div>
          <div>
            <h2 className="ProfilSubtitle">{t('profil.attendant')}</h2>
            <span className="ProfilValue">{customer.attendantMandatory ? t('profil.yes') : t('profil.no')}</span>
          </div>
        </div>
      </div> }
    </PageWrapper>
  );
};

export default Profil;