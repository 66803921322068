import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { setLanguageInitialCheckDone } from '../store/languageSlice';
import { useDispatch } from 'react-redux';

const LanguagePath = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const curPath = location.pathname;

  useEffect(() => {
    // If there's no lang, we redirect to the home page
    if (lang === undefined) {
      navigate('/' + i18n.resolvedLanguage, { replace: true });
    }

    if (lang && i18n.resolvedLanguage !== lang) {
      if (i18n.options.fallbackLng && (i18n.options.fallbackLng as string[]).includes(lang)) {
        i18n.changeLanguage(lang);
      } else {
        navigate('/' + i18n.resolvedLanguage + curPath, { replace: true });
      }
    }

    dispatch(setLanguageInitialCheckDone(true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  return <Outlet />;
};

export { LanguagePath };
