import React from 'react';
import { useTranslation } from 'react-i18next';
import { JourneyAddressDto } from '../../../../models/journey';
import './TripAddresses.scss';

const TripAddresses = ({ pickUp, dropOff }: { pickUp: JourneyAddressDto, dropOff: JourneyAddressDto }) => {
  const { t } = useTranslation();

  return (
    <div className="TripAddresses">
      <h3 className="TripAddressesTitle">{t('trips.addresses.title')}</h3>
      <div className="TripAddressesItem">
        <span>{pickUp?.shortFormattedDesc}</span>
      </div>
      <div className="TripAddressesItem">
        <span>{dropOff?.shortFormattedDesc}</span>
      </div>
    </div>
  );
};

export default TripAddresses;