import { Alert, Box } from '@mui/material';
import './PlanTripResume.scss';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import MergedLabelIconWithLetter from '../../merged-label-icon-with-letter/MergedLabelIconWithLetter';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector } from 'react-redux';
import { selectFormPlanTrip } from '../../../store/planTripSlice';
const PlanTripResume = () => {
  const formPlanTrip = useSelector(selectFormPlanTrip);
  return (
    <Alert
      variant="filled"
      severity="info"
      className="banner-resume"
      icon={false}
    >
      <Box component="div" className="banner-resume-container">
        <div className="bus-icon">
          <DirectionsBusIcon />
        </div>
        <Box component="div" className="origin">
          <MergedLabelIconWithLetter letter="A" />
          <span className="banner-address">
            {formPlanTrip.PointA?.addressLocation?.shortFormattedDescription}
          </span>
        </Box>
        <div className="destination-arrow">
          <ArrowForwardIcon />
        </div>
        <Box component="div" className="destination">
          <MergedLabelIconWithLetter letter="B" />
          <span className="banner-address">
            {formPlanTrip.PointB?.addressLocation?.shortFormattedDescription}
          </span>
        </Box>
      </Box>
    </Alert>
  );
};

export default PlanTripResume;
