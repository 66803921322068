import { Step, StepIconProps, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectActiveStep, selectSteps } from '../../../store/planTripSlice';
import './PlanTripStepper.scss';
import BackToMainMenu from '../../back-to-main-menu/BackToMainMenu';
import { useState } from 'react';

const PlanTripStepper = () => {
  const { t } = useTranslation();

  const activeStep = useSelector(selectActiveStep);
  const steps = useSelector(selectSteps);
  const [stepText, setStepText] = useState('');

  const handleMouseEnter = (label: string, index: number) => {
    let fullStepText = '';
    const stepNumber = (index + 1).toString(); // Need to increase by one since it'll be shown on the UI and index starts at 0

    if (activeStep == index) {
      fullStepText = label + ' (' + t('plan-trip.step.screenReaderCurrentStep') + ')';
    } else if (index < activeStep) {
      fullStepText = label + ' (' + t('plan-trip.step.screenReaderCompletedStep', { stepNumber: stepNumber }) + ')';
    } else {
      fullStepText = label + ' (' + t('plan-trip.step.screenReaderUpcomingStep', { stepNumber: stepNumber }) + ')';
    }

    setStepText(fullStepText);
  };

  const handleMouseLeave = () => {
    setStepText('');
  };

  return (
    <div className="PlanTripStepperContainer">
      <div className="PlanTripActionsMobile">
        <div className="homeLinkMobile">
          <BackToMainMenu />
        </div>

        <div className="PlanTripStepCounter">
          { activeStep === steps.length ? 
            t('plan-trip.step.stepsCompleted') :
            t('plan-trip.step.currentStepMobile', {
              current: activeStep + 1,
              total: steps.length,
            })
          }
        </div>
      </div>

      <div className="homeLinkDesktop">
        <BackToMainMenu />
      </div>
      <div className={ activeStep === steps.length ? 'PlanTripStepper completed' : 'PlanTripStepper'}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel aria-hidden="true" onMouseEnter={() => handleMouseEnter(t(label), index)} onMouseLeave={handleMouseLeave}>
                {t(label)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="PlanTripStepCounter PlanTripStepCounterDesktop">
        {activeStep === steps.length ? 
          t('plan-trip.step.stepsCompleted') :
          t('plan-trip.step.currentStep', {
            current: activeStep + 1,
            total: steps.length,
          })
        }
      </div>

      <div aria-live="polite" className='sr-only'>
          {stepText}
      </div>
    </div>
  );
};

export default PlanTripStepper;
