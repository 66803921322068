import React from 'react';
declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1large: React.CSSProperties;
    xlarge: React.CSSProperties;
    large: React.CSSProperties;
    normal: React.CSSProperties;
    small: React.CSSProperties;
    xsmall: React.CSSProperties;
    xxsmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1large?: React.CSSProperties;
    xlarge?: React.CSSProperties;
    large?: React.CSSProperties;
    normal?: React.CSSProperties;
    small?: React.CSSProperties;
    xsmall?: React.CSSProperties;
    xxsmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1large: true;
    xlarge: true;
    large: true;
    normal: true;
    small: true;
    xsmall: true;
    xxsmall: true;
  }
}

export const TypographyTheme =  {
  fontFamily: ['Montserrat, sans-serif'].join(','),
  h1large:
      {
        fontSize: '48px',
        fontWeight: 800,
        lineHeight: '56px',
        fontStyle: 'normal',
        letterSpacing: '-0.01em',
      },
  h1: {
    fontSize: '40px',
    fontWeight: 800,
    lineHeight: '50px',
    fontStyle: 'normal',
    letterSpacing: '-0.01em',
  },
  h2: {
    fontSize: '32px',
    fontWeight: 800,
    lineHeight: '40px',
    fontStyle: 'normal',
    letterSpacing: '-0.01em',
  },
  h3: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    fontStyle: 'normal',
    letterSpacing: '-0.01em',
  },
  h4: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    fontStyle: 'normal',
    letterSpacing: '-0.01em',
  },
  h5: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
    fontStyle: 'normal',
    letterSpacing: '-0.01em',
  },
  h6: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    fontStyle: 'normal',
  },
  xlarge: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
    fontStyle: 'normal',
  },
  large: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
    fontStyle: 'normal',
  },
  normal: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    fontStyle: 'normal',
  },
  small: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    fontStyle: 'normal',
  },
  xsmall: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px',
    fontStyle: 'normal',
  },
  xxsmall: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '-0.01em',
  },
};