import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DayOfWeek } from '../../../../models/journey';
import { capitalize } from '../../../../utils/text';
import './TripDetailsClientInfo.scss';

interface TripDetailsClientInfoProps {
  client: string,
  matricule?: number,
  date?: string,
  creationDate?: string,
  phone: string,
  requestStartDate?: string,
  requestEndDate?: string,
  days?: DayOfWeek[]
}

const TripDetailsClientInfo = ({
  client,
  matricule,
  date,
  creationDate,
  phone,
  requestStartDate,
  requestEndDate,
  days,
}: TripDetailsClientInfoProps) => {
  const { t } = useTranslation();
  const [translatedDays, setTranslatedDays] = useState<string[]>([]);

  useEffect(() => {
    const translations = days?.map((day) => t(`trips.tripCard.tripDaysFull.${day}`));
    if (translations) {
      setTranslatedDays(translations);
    }
  }, [days, t]);

  const formatDate = (dateToFormat: string) => {
    const formattedDate = dayjs(dateToFormat).format('YY-MM-DD');
    return formattedDate === '01-01-01' ? '-' : formattedDate;
  };

  const formatLongDate = (dateToFormat: string) => {
    return dayjs(dateToFormat).format(t('trips.tripDetails.longDateFormat'));
  };

  return (
    <div className="TripDetailsClientInfo">
      <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.client')}</h3>
        <span className="TripDetailsClientInfoValue">{client}</span>
      </div>
      <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.matricule')}</h3>
        <span className="TripDetailsClientInfoValue">{matricule ? matricule : ''}</span>
      </div>
      {creationDate && <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.creationDate')}</h3>
        <span className="TripDetailsClientInfoValue">{capitalize(formatLongDate(creationDate) )}</span>
      </div>}
      {date && <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.date')}</h3>
        <span className="TripDetailsClientInfoValue">{capitalize(formatLongDate(date))}</span>
      </div>}
      <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.phone')}</h3>
        <span className="TripDetailsClientInfoValue">{phone}</span>
      </div>
      {requestStartDate && <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.duration')}</h3>
        <span className="TripDetailsClientInfoValue">{formatDate(requestStartDate)} {t('trips.duration.to')} {formatDate(requestEndDate ?? '')}</span>
      </div>}
      {((days?.length ?? 0) > 0) && <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.frequency')}</h3>
        <span className="TripDetailsClientInfoValue">{capitalize(translatedDays.join(', '))}</span>
      </div>}
    </div>
  );
};

export default TripDetailsClientInfo;