import { useMsal } from '@azure/msal-react';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAccessLoginRequest } from '../authConfig';

const AxiosInterceptorProvider = ({ children }: { children: any }) => {

  const { instance } = useMsal();
  const { i18n } = useTranslation();

  const getAccessToken = async () => {
    const account = instance.getActiveAccount();
    const accessLoginRequest = getAccessLoginRequest(i18n.language);
    return instance.acquireTokenSilent({
      account: account!,
      scopes: accessLoginRequest.scopes
    }).then((tokenResponse) => {
      return tokenResponse.accessToken;
    }).catch((error) => {
      console.error(error);
      instance.acquireTokenRedirect({
        ...accessLoginRequest,
        account: account!
      });
      return '';
    });
  };

  useEffect(() => {
    const requestInterceptor = async (config: InternalAxiosRequestConfig<any>) => {
      const accessToken = await getAccessToken();
      const apimSubscriptionKey = process.env.REACT_APP_APIM_SUB_KEY;
      if (accessToken) {
        config.headers.Authorization = 'Bearer ' + accessToken;
      }
      config.headers['Content-Type'] = 'application/json';
      if (apimSubscriptionKey) {
        config.headers.set('Ocp-Apim-Subscription-Key', apimSubscriptionKey);
      }
      return config;
    };
    const errInterceptor = (error: any) => {
      Promise.reject(error);
    };

    const requestUseInterceptor = axios.interceptors.request.use(requestInterceptor, errInterceptor);

    const responseInterceptor = async (response: any) => {
      return response;
    };

    const errResponseInterceptor = (error: any) => {
      const errorCode = error.response.data.errorCode;

      if (errorCode === 1) { // Maintenance
        window.dispatchEvent(new CustomEvent('maintenance', { detail: error.response.data.errorMessage }));
      } else if (errorCode === 2) { // No access
        window.dispatchEvent(new CustomEvent('noAccess', { detail: error.response.data.errorMessage }));
      }

      return Promise.reject(error);
    };

    const responseUseInterceptor = axios.interceptors.response.use(responseInterceptor, errResponseInterceptor);

    return () => {
      axios.interceptors.request.eject(requestUseInterceptor);
      axios.interceptors.response.eject(responseUseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export { AxiosInterceptorProvider };
