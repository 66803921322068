import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../layout/loader/Loader';
import { CustomerDto } from '../../../../models/customer';
import { JourneyDto } from '../../../../models/journey';
import './CancelTripDialog.scss';


interface CancelTripDialogProps {
  trip?: JourneyDto,
  title: string,
  text: string,
  isOpened: boolean,
  isLoading: boolean,
  canCancelTrip: boolean,
  customer?: CustomerDto,
  onCloseDialog: () => void,
  onCancelTrip: (trip?: JourneyDto) => void
}

const CancelTripDialog = ({ trip, isOpened, isLoading, canCancelTrip, onCloseDialog, onCancelTrip, title, text }: CancelTripDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      onClose={onCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpened}
      className="CancelTripDialog"
    >
      <DialogTitle id="customized-dialog-title">
        {t(title)}
      </DialogTitle>
      <IconButton
        aria-label={t('cancelTrip.close')}
        onClick={onCloseDialog}
        sx={{
          position: 'absolute',
          right: 5,
          top: 10,
          color: '#222121',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers tabIndex={0}>
        { isLoading && <Loader></Loader> }
        <div dangerouslySetInnerHTML={{ __html: t(text) }}></div>
      </DialogContent>
      <DialogActions>
        {canCancelTrip && <>
          <Button onClick={() => onCloseDialog()}>
            {t('cancelTrip.back')}
          </Button>
          <Button variant="contained" onClick={() => onCancelTrip(trip)}>
            {t('cancelTrip.submit')}
          </Button>
        </>}
        {!canCancelTrip && <>
          <Button variant="contained" onClick={() => onCloseDialog()}>
            {t('cancelTrip.close')}
          </Button>
        </>}
      </DialogActions>
    </Dialog>
  );
};

export default CancelTripDialog;