declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

export const colorTheme = {
  action:{
    // hoverOpacity: 0.04,
  },
  primary: {
    100: '#B3D9E0',
    300: '#4DA7B8',
    500: '#008199',
    700: '#006E86',
    900: '#00516B',
    main: '#008199',
  },
  secondary: {
    100: '#D8CED9',
    300: '#A38EA7',
    500: '#008199',
    700: '#694B6E',
    900: '#4C3051',
    main:'#7C5D81',
  },
  tertiary: {
    100: '#EFDCCE',
    300: '#F7A389',
    500: '#F1776A',
    700: '#ED6457',
    900: '#B94065', 
    main: '#F1776A',                 
  },
  grey: {
    100: '#F1F3F4',
    200: '#E3E7E8',
    300: '#C7CFD1',
    400: '#ABB7BA',
    500: '#8F9FA3',
    600: '#73868C',
    700: '#5C6C70',
    800: '#455154',
    900: '#2B373B', 
    main: '#8F9FA3',  
  },
  black: {
    main: '#222121',
  },
  white: {
    main: '#ffffff',
  },
  success: {
    100: '#D0FBDE',
    300: '#7DE8A1',
    500: '#11642D',
    700: '#0C5222',
    900: '#053611',
    main: '#11642D',
  },
  info: {
    100: '#E7F5FF',
    300: '#A3D4F5',
    500: '#0071BA',
    700: '#005EAB',
    900: '#004294',
    main: '#0071BA',
  },
  warning: {
    100: '#FCEFC5',
    300: '#FFDB66',
    500: '#EDA925',
    700: '#C18E3D',
    900: '#3F3A27',
    main: '#EDA925',
  },
  error: {
    100: '#FFCCCC',
    300: '#FF8A8A',
    500: '#A32929',
    700: '#911F1F',
    900: '#770F0F',
    main: '#A32929',
  },
  exo1: {
    main: '#B03C43',
  },
  exo2: {
    main: '#85B648',
  },
  exo3: {
    main: '#6C3386',
  },
  exo4: {
    main: '#646569',
  },
  exo5: {
    main: '#D02C79',
  },
  exo6: {
    main: '#479FAD',
  },
};