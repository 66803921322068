
export const CustomMuiMobileStepper = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: '100%',
          flexGrow: 1,
          '& .MuiMobileStepper-dotActive ~ div':{
            backgroundColor: '#D8CED9',
          },
        },
        dots: {
          width: '100%',
        },
        dot: {
          width: '20%', //Non dynamique - 5 étapes selon le Figma
          height: '5px',
          borderRadius: '4px',
          backgroundColor: '#7C5D81',
          margin: '0 4px',
        },
        dotActive: {
          backgroundColor: '#7C5D81',
        },
      },
    },
  };
};
