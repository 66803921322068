import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../api/shared';
import { newAddressDto } from '../models/address';
import { FormPlanTrip, HourTypeChoice } from '../models/form-plan-trip';
import { JourneyDto } from '../models/journey';
import type { RootState } from './store';
export interface PlanTripState {
  activeStep: {
    value: number;
  };
  steps: {
    value: string[];
  };
  formTripPlan: {
    value: FormPlanTrip;
  };
  journey: {
    value?: Array<JourneyDto>;
  };
  planJourneyCallStatus: {
    value: ApiCallStatus;
  };
  planJourneyErrorMessage: {
    value: string;
  };
  saveJourneyCallStatus: {
    value: ApiCallStatus;
  };
  saveJourneyErrorMessage: {
    value: string;
  };
  confirmJourneyCallStatus: {
    value: ApiCallStatus;
  };
  confirmJourneyErrorMessage: {
    value: string;
  };
}

// Define the initial state
const initialState: PlanTripState = {
  activeStep: {
    value: 0,
  },
  steps: {
    value: [
      'plan-trip.step.locations',
      'plan-trip.step.date-time',
      'plan-trip.step.confirmation',
    ],
  },
  formTripPlan: {
    value: {
      PointA: newAddressDto(),
      PointB: newAddressDto(),
      Date: '',
      Time: '',
      HourType: HourTypeChoice.Arrival,
      AssistiveDevices: [],
      isError: false,
    },
  },
  journey: {
    value: undefined,
  },

  planJourneyCallStatus: {
    value: ApiCallStatus.Initial,
  },
  planJourneyErrorMessage: {
    value: '',
  },
  saveJourneyCallStatus: {
    value: ApiCallStatus.Initial,
  },
  saveJourneyErrorMessage: {
    value: '',
  },
  confirmJourneyCallStatus: {
    value: ApiCallStatus.Initial,
  },
  confirmJourneyErrorMessage: {
    value: '',
  },
};

export const planTripSlice = createSlice({
  name: 'plan-trip',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep.value = action.payload;
    },
    setFormPlanTripValue: (state, action: PayloadAction<FormPlanTrip>) => {
      state.formTripPlan.value = action.payload;
    },
    setJourneyValue: (state, action: PayloadAction<Array<JourneyDto>>) => {
      state.journey.value = action.payload;
    },
    setPlanJourneyCallStatusValue: (
      state,
      action: PayloadAction<ApiCallStatus>
    ) => {
      state.planJourneyCallStatus.value = action.payload;
    },
    setPlanJourneyErrorMessage: (state, action: PayloadAction<string>) => {
      state.planJourneyErrorMessage.value = action.payload;
    },
    setSaveJourneyCallStatusValue: (
      state,
      action: PayloadAction<ApiCallStatus>
    ) => {
      state.saveJourneyCallStatus.value = action.payload;
    },
    setSaveJourneyErrorMessage: (state, action: PayloadAction<string>) => {
      state.saveJourneyErrorMessage.value = action.payload;
    },
    setConfirmJourneyCallStatusValue: (
      state,
      action: PayloadAction<ApiCallStatus>
    ) => {
      state.confirmJourneyCallStatus.value = action.payload;
    },
    setConfirmJourneyErrorMessage: (state, action: PayloadAction<string>) => {
      state.confirmJourneyErrorMessage.value = action.payload;
    },
    resetPlanTrip: (state, action: PayloadAction<void>) => {
      return initialState;
    },
    setAssisteDevice: (state, action: PayloadAction<string>) => {
      state.formTripPlan.value.AssistiveDevices = [action.payload];
    },
  },
});

export const { setActiveStep } = planTripSlice.actions;
export const { setFormPlanTripValue } = planTripSlice.actions;
export const { setJourneyValue } = planTripSlice.actions;
export const { setPlanJourneyCallStatusValue } = planTripSlice.actions;
export const { setPlanJourneyErrorMessage } = planTripSlice.actions;
export const { setSaveJourneyCallStatusValue } = planTripSlice.actions;
export const { setSaveJourneyErrorMessage } = planTripSlice.actions;
export const { setConfirmJourneyCallStatusValue } = planTripSlice.actions;
export const { setConfirmJourneyErrorMessage } = planTripSlice.actions;
export const { resetPlanTrip } = planTripSlice.actions;
export const { setAssisteDevice } = planTripSlice.actions;

export const selectActiveStep = (state: RootState) =>
  state.planTrip.activeStep.value;

export const selectSteps = (state: RootState) => state.planTrip.steps.value;

export const selectFormPlanTrip = (state: RootState) =>
  state.planTrip.formTripPlan.value;

export const selectJourney = (state: RootState) => state.planTrip.journey.value;

export const selectPlanJourneyCallStatus = (state: RootState) =>
  state.planTrip.planJourneyCallStatus.value;
export const selectPlanJourneyErrorMessage = (state: RootState) =>
  state.planTrip.planJourneyErrorMessage.value;
export const selectSaveJourneyCallStatus = (state: RootState) =>
  state.planTrip.saveJourneyCallStatus.value;
export const selectSaveJourneyErrorMessage = (state: RootState) =>
  state.planTrip.saveJourneyErrorMessage.value;
export const selectConfirmJourneyCallStatus = (state: RootState) =>
  state.planTrip.confirmJourneyCallStatus.value;
export const selectConfirmJourneyErrorMessage = (state: RootState) =>
  state.planTrip.confirmJourneyErrorMessage.value;

export default planTripSlice.reducer;
