import React from 'react';
import { useTranslation } from 'react-i18next';
import  dayjs from 'dayjs';
import './TripDuration.scss';

interface TripDurationProps {
  requestStartDate: string,
  requestEndDate: string,
  frequency: number
}

const TripDuration = ({
  requestStartDate,
  requestEndDate,
  frequency,
}: TripDurationProps) => {
  const { t } = useTranslation();

  const formatDate = (date: string) => {
    const formattedDate = dayjs(date).format('YY-MM-DD');
    return formattedDate === '01-01-01' ? '-' : formattedDate;
  };

  return (
    <div className="TripDuration">
      <div className="TripDurationDuration">
        <h3 className="TripDurationTitle">{t('trips.duration.duration')}</h3>
        <span className="TripDurationValue">
          {formatDate(requestStartDate)} {t('trips.duration.to')} {formatDate(requestEndDate)}
        </span>
      </div>
      <div>
        <h3 className="TripDurationTitle">{t('trips.duration.frequency')}</h3>
        <span className="TripDurationValue">{frequency === 1 ? t('trips.duration.eachWeek') : t('trips.duration.every', { amount: frequency })}</span>
      </div>
    </div>
  );
};

export default TripDuration;