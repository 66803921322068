export const CustomMuiSwitch = (theme: any) => {
  theme.components = {
    ...theme.components,
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '70px',
          height: '48px',
          // default configuration for base switch
          '& .MuiSwitch-thumb': {
            marginTop: '5px',
            width: '20px',
            height: '20px',
            color: theme.palette.white.main,
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 1,
            },
          },
          '& .MuiButtonBase-root': {
            left: '5px',
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            left: '7px',
          },
          '& .MuiSwitch-switchBase.MuiSwitch-colorPrimary:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: theme.palette.success.main,
            opacity: 1,
            height: '24px',
            borderRadius: '24px',
          },
          '& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
            backgroundColor: theme.palette.grey.main,
            opacity: 1,
          },
          '& .MuiSwitch-track': {
            backgroundColor: theme.palette.error.main,
            opacity: 1,
            height: '24px',
            borderRadius: '24px',
          },
        },
               
      },
    },
  };
};
