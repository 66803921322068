import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider, enUS, frFR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { getAccessLoginRequest } from '../authConfig';
import ServerError from '../components/alert/server-error/ServerError';
import { LanguagePath } from '../i18n/LanguagePath';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';
import '../scss/index.scss';
import { fetchCustomerAsync } from '../store/customerSlice';
import { selectLanguageInitialCheckDone } from '../store/languageSlice';
import store from '../store/store';
import { theme } from '../theme/theme';
import { AxiosInterceptorProvider } from '../utils/AxiosInterceptorProvider';
import { ErrorListener } from '../utils/ErrorListener';
import { getLanguageFromUrl } from '../utils/language';
import Home from '../views/home/Home';
import Maintenance from '../views/maintenance/Maintenance';
import NoAccess from '../views/noAccess/NoAccess';
import PlanTrip from '../views/plan-trip/PlanTrip';
import Profil from '../views/profil/Profil';
import Trips from '../views/trips/Trips';
import './App.scss';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

ReactGA.initialize( process.env.REACT_APP_GA_TRACKING_ID ?? '', 
  { 
    testMode: process.env.REACT_APP_GA_TEST_MODE  == 'true' ?? false 
  });

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID ?? '',
};
TagManager.initialize(tagManagerArgs);

const ErrorComponent = () => {
  const { t } = useTranslation();

  return <div className="CustomerError">
    <ServerError>{t('error.loginErrorText')}</ServerError>
  </div>;
};

const App = () => {
  const { i18n, t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();

  const languageInitialCheckDone = useSelector(selectLanguageInitialCheckDone);

  const frenchLocale =
    frFR.components.MuiLocalizationProvider.defaultProps.localeText;
  const englishLocale =
    enUS.components.MuiLocalizationProvider.defaultProps.localeText;

  const authRequest = getAccessLoginRequest(getLanguageFromUrl());

  const setPageMeta = () => {
    document.getElementsByTagName('html')?.[0]?.setAttribute('lang', i18n.language);
    document.head.querySelectorAll('[name="description"]')?.[0].setAttribute('content', t('meta.title'));
    document.head.querySelectorAll('[name="application-name"]')?.[0].setAttribute('content', t('meta.title'));
    document.head.querySelectorAll('[name="og:description"]')?.[0].setAttribute('content', t('meta.title'));
    document.title = t('meta.title');
  };
  
  useEffect(() => {
    dayjs.locale(i18n.language);
    setPageMeta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    // By default, language is "fr". The first call here can be "fr" since sometimes isAuthenticated changes before the language is resolved.
    // To solve this, we also make sure the language initial check is completed
    if (isAuthenticated && languageInitialCheckDone) {
      store.dispatch(fetchCustomerAsync(i18n.language));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, languageInitialCheckDone]);
 
  const location = useLocation();
  //Tracking de pageviews pour Google Analytics
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
        // Define the translations to have the right placeholders (e.g. `JJJJ` for the year).
        localeText={
          i18n.language === 'fr' ? frenchLocale : englishLocale
        }
      >
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
        >
          <AxiosInterceptorProvider>
            <Header></Header>
            <main>
              <AuthenticatedTemplate>
                  <Routes>
                    {/* LanguagePath below needs to be first at all times as it will resolve the language for the pages underneath.
                        Anything above this will be using the default language ("fr") regardless of the path*/}
                    <Route path="/" element={<LanguagePath />} />
                    <Route path="/:lang" element={<LanguagePath />}>
                      <Route path="" element={<ErrorListener />}>
                        <Route path="" element={<Home />} />
                        <Route path="maintenance" element={<Maintenance />} />
                        <Route path="noAccess" element={<NoAccess />} />
                        <Route path="planifier-un-trajet" element={<PlanTrip />} />
                        <Route path="consulter-mon-profil" element={<Profil />} />
                        <Route path="voir-mes-deplacements" element={<Trips />} />
                      </Route>
                    </Route>
                  </Routes>
              </AuthenticatedTemplate>
            </main>
            <Footer></Footer>
          </AxiosInterceptorProvider>
        </MsalAuthenticationTemplate>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
