import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAccessLoginRequest, getAccessLogoutRequest } from '../../authConfig';
import Logo from '../../icons/Logo';
import { selectCustomerIsLoading } from '../../store/customerSlice';
import Loader from '../loader/Loader';
import './Header.scss';

const Header = () => {
  const { t, i18n } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const isCustomerLoading = useSelector(selectCustomerIsLoading);

  const handleSignOut = () => {
    instance.logoutRedirect(getAccessLogoutRequest(i18n.language));
  };

  const handleSignIn = () => {
    instance.loginRedirect(getAccessLoginRequest(i18n.language));
  };
  
  return (
    <>
      { isCustomerLoading && <Loader></Loader> }
      <header className="Header">
        <div className="Header__top">
          <div className="Header__brand">
            <a href={t('header.home-page-url')} aria-label={t('header.back-to-home-page-aria-label')}>
              <span aria-hidden="true">
                <Logo />
              </span>
            </a>
          </div>
          <span className="Header__title d-none d-md-block">{t('header.title')}</span>
          { isAuthenticated && <button
            className="Header__logout"
            onClick={handleSignOut}
          >{t('header.sign-out')}</button> }
          { !isAuthenticated && <button
            className="Header__logout"
            onClick={handleSignIn}
          >{t('header.sign-in')}</button> }
        </div>
      </header>
    </>
  );
};

export default Header;