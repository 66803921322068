export const CustomMuiCheckbox = (theme:any) =>{
  theme.components = {
    ...theme.components,
    MuiCheckbox:{
      styleOverrides:{
        root: {
          color: theme.palette.grey[700],
          '& .MuiSvgIcon-root':{
            width:'20px',
            height:'20px',
            borderRadius:'4px',
          },
          '&.Mui-checked':{
            color:theme.palette.primary[500],
          },
          '&.Mui-checked&.Mui-disabled':{
            color:theme.palette.grey[400],
          },
          '&.Mui-disabled':{
            color:theme.palette.grey[200],
          },
        },
      },
    },
  };
};