import {
  LocationKeyDto,
  MunicipalityDto,
  StreetOrientationDto,
  StreetTypeDto,
} from './address';
import { TripAssistiveDeviceJourneyDto } from './device';
import { TransportationMode } from './transportation';

export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum RequestType {
  Occasional = 'Occasional',
  Regular = 'Regular',
}

export enum RequestedTimeType {
  Arrival = 'Arrival',
  Departure = 'Departure',
}

export interface JourneyKeyDto {
  scheduleId?: string;
  journeyId?: string;
}

export interface JourneyAddressDto {
  addressId: number;
  landmarkId?: string;
  registeredAddressType?: string;
  civicNumber?: string;
  street?: string;
  municipalityName?: string;
  longitude: number;
  latitude: number;
  isServiceAddress: boolean;
  locationKey: LocationKeyDto;
  shortFormattedDesc?: string;
  longFormattedDesc?: string;
  suite?: string;
  streetType: StreetTypeDto;
  streetOrientation: StreetOrientationDto;
  municipality: MunicipalityDto;
  building?: string;
  postalCode?: string;
  externalDescription?: string;
}

export interface JourneyTripDto {
  dropOffAddress: JourneyAddressDto;
  arrivalDateTime: string;
  key: number;
  pickUpAddress: JourneyAddressDto;
  departureDateTime: string;
  customerHasReportedDelay: boolean;
  canReportLateByCustomer: boolean;
  duration?: string;
  distance?: string;
  line?: string;
  transportationMode: TransportationMode;
}

export interface JourneyTripWithPickUpTime {
  earliestPickUpDateTime: string;
  latestPickUpDateTime: string;
  requestedTime: string;
  requestedTimeType?: RequestedTimeType;
  trip: JourneyTripDto;
}

export interface JourneyDto {
  key: JourneyKeyDto;
  pickUpAddress: JourneyAddressDto;
  dropOffAddress: JourneyAddressDto;
  requestType?: RequestType;
  requestedTime: string;
  requestedTimeType?: RequestedTimeType;
  reasonCode?: string;
  canBeCancelled: boolean;
  hoursModified: boolean;
  duration?: string;
  mustBeAcknowledged: boolean;
  ackMessage?: string;
  effectiveDate: string;
  dayType: DayOfWeek;
  requestStartDate: string;
  requestEndDate: string;
  earliestPickUpDateTime: string;
  latestPickUpDateTime: string;
  earliestDropOffDateTime: string;
  latestDropOffDateTime: string;
  journeyStatus?: string;
  journeyType?: string;
  scheduleType?: string;
  contactPhone?: string;
  frequency: number;
  assistiveDevices?: TripAssistiveDeviceJourneyDto[];
  tripPassengers?: string[];
  trips?: JourneyTripDto[];
}
