
const IconProfil = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 110 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="55" cy="55" r="50" fill="#B5DDE1" stroke="black" strokeWidth="10"/>
      <circle cx="36" cy="40" r="8" fill="white"/>
      <circle cx="36" cy="40" r="8" fill="white"/>
      <circle cx="36" cy="40" r="8" fill="white"/>
      <circle cx="74" cy="60" r="8" fill="white"/>
      <circle cx="74" cy="60" r="8" fill="white"/>
      <circle cx="74" cy="60" r="8" fill="white"/>
      <circle cx="55" cy="56" r="50" fill="#F6E8E1" stroke="black" strokeWidth="10"/>
      <circle cx="55.4679" cy="46.6377" r="10.5" fill="white" stroke="black" strokeWidth="6"/>
      <path
        // eslint-disable-next-line max-len
        d="M69.2172 75.6377H40.7186C41.9696 69.4667 47.7636 64.6377 54.9679 64.6377C62.1722 64.6377 67.9662 69.4667 69.2172 75.6377Z"
        fill="black"
        fillOpacity="0.87"
        stroke="black"
        strokeWidth="5"
      />
    </svg>
  );
};

export default IconProfil;