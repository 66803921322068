import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, AlertTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetPlanTrip } from '../../../store/planTripSlice';
import './PlanTripSuccess.scss';
import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
 
TagManager.dataLayer({
  dataLayer: {
    event: 'planTrip',
    step: 4,
  }
});

const PlanTripSuccess = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlePlanNewTrip = () => {
    dispatch(resetPlanTrip());
  };

  const planTripRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (planTripRef && planTripRef.current) {
      planTripRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planTripRef]);

  return (
    <Alert className="ServerSuccess PlanTripSuccess" severity="success" icon={<CheckCircleIcon />} onClose={props.onClose} ref={planTripRef} >
      <AlertTitle>{t('plan-trip.success.title')}</AlertTitle>
      {t('plan-trip.success.text')}
      <Button
        variant="contained"
        color="success"
        size="small"
        onClick={handlePlanNewTrip}
      >
        {t('plan-trip.success.planNewTrip')}
      </Button>
    </Alert>
  );
};

export default PlanTripSuccess;