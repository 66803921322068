import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TripAssistiveDeviceJourneyDto } from '../../../../models/device';
import './TripDetailsAssistance.scss';

interface TripDetailsAssistanceProps {
  assistiveDevices?: TripAssistiveDeviceJourneyDto[];
  tripPassengers?: string[];
}

const TripDetailsAssistance = ({ assistiveDevices, tripPassengers }: TripDetailsAssistanceProps) => {
  const { t } = useTranslation();
  const [filteredPassengers, setFilteredPassengers] = useState<string[]>([]);

  useEffect(() => {
    setFilteredPassengers(tripPassengers?.filter((passenger) => passenger !== 'Customer') ?? []);
  }, [tripPassengers]);

  return (
    <div className="TripDetailsAssistance">
      <div>
        <h3 className="TripDetailsAssistanceTitle">{t('trips.tripDetails.youRequested')}</h3>
        {(!assistiveDevices || assistiveDevices.length === 0) && <span className="TripDetailsAssistanceValue">{t('trips.tripDetails.noAssistiveDevice')}</span>}
        {(assistiveDevices && assistiveDevices.length > 0) && <>
          <ul className="TripDetailsAssistanceList">
            {assistiveDevices.map((device, index) => <li key={index}><span className="TripDetailsAssistanceValue">{device.description}</span></li>)}
          </ul>
        </>}
      </div>
      <div>
        <h3 className="TripDetailsAssistanceTitle">{t('trips.tripDetails.attendant')}</h3>
        {filteredPassengers.length === 0 && <span className="TripDetailsAssistanceValue">{t('trips.tripDetails.noAttendant')}</span>}
        {filteredPassengers.length > 0 && <span className="TripDetailsAssistanceValue">
          {t('trips.tripDetails.attendantAmount', { count: filteredPassengers.length })}
        </span>}
      </div>
    </div>
  );
};

export default TripDetailsAssistance;